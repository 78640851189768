
import React, { useRef, useState, forwardRef, useImperativeHandle } from 'react';
import AudioSpectrum from 'react-audio-spectrum';
import AudioVisualizer from "@tiagotrindade/audio-visualizer"
import { gsap } from 'gsap';
import { useGlobalState } from './App';
import { useFrame } from '@react-three/fiber';
import { Html } from '@react-three/drei';

const AudioController = forwardRef(function (props, ref) {
  const [is_eq_ready, setEqReady] = useState(false);
  const [isPlaying, setPlaying] = useState(false);
  const audio_ref = useRef(null);

  const [container, setContainer] = useState(null);
  const [canvas, setCanvas] = useState(null);
  const [ctx, setContext] = useState(null);

  const [audioSource, setAudioSource] = useState(null);
  const [analyser, setAnalyser] = useState(null);
  const [bufferLength, setBufferLength] = useState(null);

  const barWidth = 4;
  const barGap = 2;

  /*
  Use this to debug the subtitles timings
  window.onkeypress = function(e) { console.log("lol", getAudioTime().toFixed(1))};
  */


  const song_text = [
    [0, ""],
    [1, "Dear stranger"],
    [2.65, "I know how you feel"],
    [5, "Piercing"],
    [6.2, "Falling"],
    [7.4, "Over and over again"],
    [9.4, ""],

    [11.5, "From the deep dark sky"],
    [13.5, "Right where you think the ghosts are"],
    [16.2, "I traveled the galaxy"],
    [17.6, "To shine a light"],
    [19.2, "On what you do not see"],
    [21.2, ""],

    [24.7, "Dear stranger "],
    [26.4, "I know how you feel"],
    [28.5, "Staring"],
    [29.6, "Wondering"],
    [30.9, "Over and over again"],
    [32.9, ""],

    [33.6, "If you think nature is mystery"],
    [35.9, "Then you shall now see"],
    [37.8, "Before it is too late"],
    [39.6, "And all is taken to the grave"],
    [41.9, "Now you shall see"],
    [43.9, ""],

    [47.5, "Dear stranger "],
    [49.3, "I know how you feel"],
    [51.5, "Wishing"],
    [52.3, "Longing "],
    [54, "Over and over again"],
    [56, ""],

    [57.3, "At last on Earth we meet"],
    [59.7, "Think about the chances"],
    [61.8, "We've come so far on our journey"],
    [64.7, "Stardust, do you feel it"],
    [67.1, "The universe in a heartbeat"],
    [69.1, ""],
    [70.0, ""]
  ];

  function getAudioTime() {
    try {
      return audio_ref.current.currentTime;
    } catch (error) {
      return 0;
    }
  }

  function getTextLine(index) {
    return song_text[index][1].slice();
  }
  function getTextTime(index) {
    return song_text[index][0];
  }

  const setTextIndex = index => {
    setTextState(existingValues => ({
      ...existingValues,
      index: index
    }))
  }
  const setTextTime = time => {
    setTextState(existingValues => ({
      ...existingValues,
      text_time: time
    }))
  }
  const setTextLine = text => {
    setTextState(existingValues => ({
      ...existingValues,
      text_line: text
    }))
  }

  const [text_state, setTextState] = useState({
    index: 0,
    text_time: getTextTime(0),
    text_line: getTextLine(0)
  });

  

  function setupEqualizer(audio) {
    if(is_eq_ready){
      return;
    }
    console.log("setting up equalizer");

    let e = document.getElementById('equalizer');
    e.width = 64;
    e.height = 64;
    setContainer(e);

    let c = document.getElementById("equalizer_canvas");
    setCanvas(c);

    let c3 = c.getContext("2d");
    setContext(c3);

    const audioCtx = new (window.AudioContext || window.webkitAudioContext)();

    let audio_source = document.querySelector("#audio_lyrics");
    let as = audioCtx.createMediaElementSource(audio_source);
    setAudioSource(as);

    let aa = audioCtx.createAnalyser();
    aa.fftSize = 32;
    setAnalyser(aa);

    as.connect(aa);
    aa.connect(audioCtx.destination);

    let bl = aa.frequencyBinCount;

    setBufferLength(bl);
    setEqReady(true);
    console.log("equalizer ready");
  }

  useImperativeHandle(ref, () => ({
    toggleAudio(value) {
      let a = audio_ref.current;
      if (audioSource === null) {
        setupEqualizer(a);
      }

      console.log("toggle audio");

      if(value === undefined){
        value = a.paused;
        console.log("no value provided, assigned => ", value);
      }

      if(value){ //play
        setPlaying(true);
        if(a.paused){
          a.play();
        }
        gsap.timeline()
          .to(".audio_text", { duration: 1, opacity: 1 }, 0)
          .to(".audio_player", { duration: 1, opacity: 1 }, 0);

        props.scrollable_content.current.toggleGlitch(true);        
        console.log("play lyrics");

      } else { //pause
        setPlaying(false);
        if(!a.paused){
          a.pause();
        }
        gsap.timeline()
          .to(".audio_text", { duration: 1, opacity: 0 }, 0)
          .to(".audio_player", { duration: 1, opacity: 0 }, 0);

        props.scrollable_content.current.toggleGlitch(false);
        console.log("pause lyrics");
      }
    }
  }));

  useFrame(() => {
    if (isPlaying) {
      checkAudioTime();

      //canvas stuff
      let x = 0;
      let barHeight = 2;
      ctx.clearRect(0, 0, canvas.width, canvas.height);

      var dataArray =  new Uint8Array(bufferLength);
      analyser.getByteFrequencyData(dataArray);
      for (let i = 4; i < 9; i += 1) {
        barHeight = Math.max(1, dataArray[i] * 0.25);
        ctx.fillStyle = "white";
        ctx.fillRect(x, canvas.height - barHeight, barWidth, barHeight);
        x += barWidth + barGap;
      }
    }
  });

  function checkAudioTime() {
    try {
      let next_index = text_state.index + 1;
      let next_line_time = getTextTime(next_index);
      let next_line_text = getTextLine(next_index);
      let current_time = getAudioTime();

      if (next_line_time < current_time) {
        setTextIndex(next_index);
        setTextTime(next_line_time);
        setTextLine(next_line_text);
      }

      document.getElementsByClassName("audio_text")[0].innerHTML =
        text_state.text_line; // + " " + getAudioTime().toFixed(1);

    } catch (error) {
      //silence is gold.
      //console.warn("reached end of subtitles: ", error)
      // setTextIndex(0);

      // let next_line_time = getTextTime(0);
      // let next_line_text = getTextLine(0);
      // setTextTime(next_line_time);
      // setTextLine(next_line_text);
    }
  }


  return (
    <>
      <Html>
        <audio id="audio_lyrics" ref={audio_ref} src="./audio/Lyrics_lores.mp3"></audio>
      </Html>
    </>
  )
});

export default AudioController;