import { useGlobalState } from '../App';
import React, { useState, useEffect } from 'react';
import { gsap } from 'gsap';

var fps = 5;
var now;
var then = Date.now();
var interval = 1000 / fps;
var delta;
window.started_animation = false;

function TopRightLoader(props) {
  const [scroll_pos] = useGlobalState('scroll_pos');
  const [animation_id, setAnimationId] = useState(undefined);
  const [random_numbers, updateNumbersString] = useState('000000000000');

  useEffect(() => {
    if (!window.started_animation) {
      window.started_animation = true;
      if (scroll_pos < 0.025 && animation_id === undefined) {
        const id = setInterval(() => {
          updateRandomNumbers();
        }, 100);
        setAnimationId(id);
      }
    }

    if (scroll_pos >= 0.025 && scroll_pos < 0.75) {
      clearInterval(animation_id);
      setAnimationId(undefined);

      gsap.timeline()
        .to('.top_right_loader', { duration: 0.5, opacity: 0 }, 0)
        .to('.top_right_overlay', { duration: 1, opacity: 1 }, 0.5);
    } else {
      gsap.timeline()
        .to('.top_right_overlay', { duration: 0.5, opacity: 0 }, 0);
    }
  }, [scroll_pos, animation_id]);

  function updateRandomNumbers() {
    now = Date.now();
    delta = now - then;
    if (delta > interval) {
      try {
        let rand = [];
        for (let i = 0; i < 16; i++) {
          rand.push(Math.floor(Math.random() * 11));
        }
        updateNumbersString(rand.join(''));
      } catch (error) { }
      then = now - (delta % interval);
    }
  }

  return (
    <div className="top_right_loader">
      <p>{random_numbers}</p>
    </div>
  )
}

export default TopRightLoader