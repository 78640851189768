import React, { useState, useEffect } from 'react';

function ScrollToContinue(props) {
  return (
    <div className="scroll_to_continue">
      SCROLL TO CONTINUE
    </div>
  )
}

export default ScrollToContinue
