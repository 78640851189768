import React, { forwardRef, useState, useRef, useImperativeHandle } from 'react'
import { useGlobalState } from '../App';

import MeteoriteGlass from './MeteoriteGlass';
import MeteoriteReal from './MeteoriteReal';
import MeteoriteColorful from './MeteoriteColorful';
import gsap from 'gsap';

const Meteorite = forwardRef(function (props, ref) {
    const [lock_function] = useGlobalState('lock_scroll_function');
    const [isAtMicroscope] = useGlobalState('isAtMicroscope');
    const [current_page] = useGlobalState('current_page');
    const [LYRICS_SECTION] = useGlobalState('LYRICS_SECTION');
    const [isMouseOver, setMouseOver] = useState(false);
    const [zoomed_out, setZoomedOut] = useState(false);
    const [continue_animation, setContinueAnim] = useState(undefined);

    const glass = useRef();
    const real = useRef();
    const colorful = useRef();
    const meteos = useRef();

    const FALL_DURATION = 5;
    const ZOOM_OUT_DURATION = 3;
    const ZOOM_SCALE = 0.75;

    function setScrollLock(lock, caller) {
        let lf = lock_function;
        if (lock) {
            document.querySelector('.App').addEventListener('wheel', lf, { passive: false });
        } else {
            document.querySelector('.App').removeEventListener('wheel', lf, { passive: false });
        }
        console.log("meteorite set scroll lock:", lock, caller);
    }

    const handleOverIn = (e) => {
        if (current_page === LYRICS_SECTION) {
            setMouseOver(true);
        } else {
            setMouseOver(false);
        }
    }

    const handleOverOut = (e) => {
        setMouseOver(false);
    }

    useImperativeHandle(ref, () => ({
        toggleVisibility(value) {
            meteos.current.visible = value;
        },

        fall() {
            setScrollLock(true, "fall");
            gsap.timeline()
                .to(meteos.current.position, {
                    duration: FALL_DURATION, ease: "power2",
                    x: 0, y: 0, z: 0
                }, 0)
                .add(function () {
                    setScrollLock(false, "fall");

                    let anim = gsap.timeline({ paused: true })
                        .to('.scroll_down_arrow', {
                            alpha: 0.5, repeatDelay: 0, duration: 1.25, 
                            yoyo: true, repeat: -1
                        }, 3);
                    setContinueAnim(anim);
                    anim.play();

                }, FALL_DURATION);
        },

        zoomOut() {
            if (!zoomed_out) {
                setZoomedOut(true);
                setScrollLock(true, "zoomOut");
                continue_animation.pause();
                gsap.timeline()
                    .to('.scroll_down_arrow', {
                        duration: 1, opacity: 0
                    })
                    .to(meteos.current.scale, {
                        duration: ZOOM_OUT_DURATION, ease: "power4",
                        x: ZOOM_SCALE, y: ZOOM_SCALE, z: ZOOM_SCALE
                    })
                    .add(function () {
                        setScrollLock(false, "zoomOut");
                    }, ZOOM_OUT_DURATION);
            }
        },

        toggleMicroscope(value) {
            //console.log("microscope mode!");
            if (value) {
                gsap.to(meteos.current.position, { duration: 2, x: -0.25, y: 0, z: 0.1 });
            } else {
                gsap.to(meteos.current.position, { duration: 2, x: 0, y: 0, z: 0 });
            }
        },

        rotate(_x, _y) {
            gsap.to(meteos.current.rotation, {
                duration: 1, ease: "circ",
                x: _x, y: _y
            });
        }
    }));

    return (
        <group ref={ref}
            onPointerOver={handleOverIn}
            onPointerOut={handleOverOut}>
            <group ref={meteos} position={[0, 5, 0]}>
                {
                    isAtMicroscope ?
                        null
                        :
                        isMouseOver ?
                            <MeteoriteReal ref={real} />
                            :
                            <MeteoriteGlass ref={glass} />
                }
            </group>

        </group>
    );
});
export default Meteorite;
/*
{
    isAtMicroscope ?
        <MeteoriteColorful ref={colorful} />
        :
        isMouseOver ?
            <MeteoriteReal ref={real} />
            :
            <MeteoriteGlass ref={glass} />
}
*/