import { useGlobalState } from '../App';
import React, { Component, useRef, useState } from 'react';

function CreditsOverlay(props) {

  //const url = "https://www.youtube.com/embed/FAMCx6Kr1k8?rel=0&controls=0&showinfo=0"
  const video_file = "./res/videos/video_720.mp4"

  return (
    <>
      <div className="video_overlay">
        <video src={video_file} autoPlay muted loop/>
        <div className="credits_text">
          <div className="credits_person" style={{top: "15%", left: "30%"}}>
            <p>mathilde renault</p>
            <span>producer, director, artist</span>
          </div>          
          <div className="credits_person" style={{top: "30%", left: "10%"}}>
            <p>céline jouandet</p>
            <span>font designer</span>
          </div>
          <div className="credits_person" style={{top: "45%", left: "15%"}}>
            <p>rita fialho</p>
            <span>WEB DESIGNER</span>
          </div>
          <div className="credits_person" style={{top: "60%", left: "25%"}}>
            <p>frank bloem</p>
            <span>OLFACTORY DESIGNER, The snifferoo</span>
          </div>
          <div className="credits_person" style={{top: "75%", left: "20%"}}>
            <p>sebastiaan de vet</p>
            <span>Advisor GEOMORPHOLOGIST TU Delft aerospace engineering dpt.</span>
          </div>          
          <div className="credits_person" style={{top: "85%", left: "10%"}}>
            <p>bernard foing</p>
            <span>Advisor senior scientist ESTEC-ESA</span>
          </div>
          <div className="credits_person" style={{top: "25%", right: "20%"}}>
            <p>Daniel Nunes</p>
            <span>webdeveloper</span>
          </div>
          <div className="credits_person" style={{top: "40%", right: "15%"}}>
            <p>lefki mevissen</p>
            <span>technical artist</span>
          </div>
          <div className="credits_person" style={{top: "55%", right: "25%"}}>
            <p>paula garcia sans</p>
            <span>3d renders</span>
          </div>
          <div className="credits_person" style={{top: "70%", right: "20%"}}>
            <p>Tatiana Rosa</p>
            <span>Sound Designer</span>
          </div>
          <div className="credits_person" style={{top: "80%", right: "10%"}}>
            <p>bouke lacet</p>
            <span>Geological technical laborant Vrije Universiteit amsterdam</span>
          </div>
          <div className="credits_person" style={{top: "10%", right: "15%"}}>
            <p>lino thijssen</p>
            <span>programmer</span>
          </div>
        </div>
      </div>
    </>    
  )
}

export default CreditsOverlay