import { useGlobalState } from '../App';
import React, { useState } from 'react';
import { gsap, TweenLite } from 'gsap';

const scroll_thresholds = [0.25, 0.5, 0.56, 0.68, 1];

function getAltitude(scroll_position) {
  let d = scroll_position;
  let st = scroll_thresholds;
  switch (true) {
    case d < st[0]: return (179500000 - scroll_position*4 * 179490000).toFixed(3)+ " Km";
    case d < st[1]: return (10000 - (scroll_position - st[0]) * 4 * 9300).toFixed(3)+ " Km";
    case d < st[2]: return (700 - (scroll_position - st[1]) * 4 * 695.8).toFixed(3) + " Km";
    case d < st[3]: return (4.2 - (scroll_position - st[3]) * 4 * 4.2).toFixed(3) + " m";
    case d < st[4]: return 'Microscope: 30µm';
    default: return 'N/A';
  }
}

function getLayer(scroll_position) {
  let d = scroll_position;
  let st = scroll_thresholds;
  switch (true) {
    case d < st[0]: return 'Asteroid belt';
    case d < st[1]: return 'Outer Space';
    case d < st[2]: return 'Atmosphere';
    case d < st[3]: return 'Atlas Mountain, Morocco, Africa';
    case d < st[4]: return 'NWA - unclassified meteorite';
    default: return 'N/A';
  }
}

function getSpeed(scroll_position) {
  let d = scroll_position;
  let st = scroll_thresholds;
  switch (true) {
    case d < st[0]: return "42 km/sec";
    case d < st[3]: return "72 km/s to 11 km/s";
    default: return "0 m/sec";
  }
}

function getTemperature(scroll_position) {
  let d = scroll_position;
  let st = scroll_thresholds;
  switch (true) {
    case d < st[0]: return "-73";
    case d < st[1]: return "1648.889";
    case d < st[2]: return "25";
    default: return 20;
  }
}

function TopRightOverlay(props) {
  const [scroll_pos] = useGlobalState('scroll_pos');
  const [debug_value] = useGlobalState('debug_value');
  const [descent_progress ] = useGlobalState('descent_progress');
  const [scroll_y] = useGlobalState('scroll_y');

  return (
    <>
      <div className="top_right_overlay">
        <span>{getAltitude(scroll_y)} </span><span className="top_right_altitude">{getLayer(scroll_y)}</span>
        <p>{getSpeed(scroll_y)}, Temperature : {getTemperature(scroll_y)}C</p>
      </div>
      {/* <p className="debug_meteo">debug {debug_value} scroll_descent=[{descent_progress}]</p> */}
    </>
  )
}

export default TopRightOverlay