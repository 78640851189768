import React, { useRef, forwardRef, useImperativeHandle, useState, useEffect } from "react"
import { Scroll, ScrollControls, PresentationControls, Float, CameraShake } from '@react-three/drei'
import ScrollObserver from './ScrollObserver';
import Background from "./Background";
import CreditsOverlay from "./overlays/CreditsOverlay";
import { useGlobalState } from './App';
import Intro from "./transitions/Intro";
import WhiteWall from "./meteorite/WhiteWall";
import Meteorite from './meteorite/Meteorite';
import { EffectComposer, Noise, Select, Selection, Glitch } from "@react-three/postprocessing";
import { GlitchMode } from "postprocessing";
import AudioController from "./AudioController";
import MicroscopeController from "./microscopic_view/MicroscopeController";

const ScrollableContent = forwardRef(function (props, ref) {
  const [TOTAL_SECTIONS] = useGlobalState('TOTAL_SECTIONS');
  const [isAtMicroscope] = useGlobalState('isAtMicroscope');
  const [ongoing_descent] = useGlobalState('ongoing_descent');
  const meteo = useRef();
  const micro_meteo = useRef();
  const white_wall = useRef();
  const glitch = useRef();
  const audio_controller = useRef();
  const [glitch_active, setGlitch] = useState(false);
  const [adjusted_glitch, adjustedGlitch] = useState(false);

  useEffect(() => {
    if (glitch.current && !adjusted_glitch) {
      try {
        let g = glitch.current;

        g.setGlitchRatio(1);

        g.minDelay(1);
        g.maxDelay(3);

        g.minDuration(0.05);
        g.maxDuration(0.2);

        g.minStrength(0.1);
        g.maxStrength(0.1);

        adjustedGlitch(true);
        console.log("adjusted glitch properties");
      } catch (error) { }
    }
  });

  function lockScroll() {

  }

  useImperativeHandle(ref, () => ({
    toggleGlitch(v) {
      setGlitch(v);
    },

    toggleAudio(v) {
      audio_controller.current.toggleAudio(v);
    }
  }));

  return (
    <>
      <ScrollControls
        pages={TOTAL_SECTIONS} // Each page takes 100% of the height of the canvas
        distance={1.5} // A factor that increases scroll bar travel (default: 1)
        damping={100} // Friction, higher is faster (default: 4)
        horizontal={false} // Can also scroll horizontally (default: false)
        infinite={false} // Can also scroll infinitely (default: false)
      >
        <Background frameloop="demand" />
        <WhiteWall ref={white_wall} />

        <group>
          <pointLight intensity={3} decay={2} position={[171.16, 278.06, -198.24]} rotation={[1.89, 0.88, -2.05]} scale={20000} />
          <pointLight intensity={3} decay={2} position={[-178.82, -240.51, 240.66]} rotation={[1.89, 0.88, -2.05]} scale={20000} />
          <directionalLight intensity={10} decay={2} color="#0f0f0f" position={[-1, 1, 1]} scale={100} />
          <PresentationControls
            global={false} // Spin globally or by dragging the model
            cursor={true} // Whether to toggle cursor style on drag
            snap={false} // Snap-back to center (can also be a spring config)
            speed={isAtMicroscope ? 0 : 1} // Speed factor
            zoom={1} // Zoom factor when half the polar-max is reached
            rotation={[0, 0, 0]} // Default rotation
            polar={[-Infinity, Infinity]} // Vertical limits
            azimuth={[-Infinity, Infinity]}>

            <Selection>
              <Select>
                <EffectComposer multisampling={4}>
                  <Noise opacity={0.01} />
                  {glitch_active ? <Glitch ref={glitch} active={true} mode={GlitchMode.SPORADIC} /> : null}
                </EffectComposer>
                <Float
                  rotationIntensity={0.75}
                  floatIntensity={0.25}
                  speed={1}>
                  <CameraShake
                    maxRoll={0.8}
                    pitchFrequency={ongoing_descent ? 7 : 0}
                    rollFrequency={ongoing_descent ? 3 : 0}
                  />
                  <Meteorite ref={meteo} position={[0, 5, 0]} scale={[1, 1, 1]} />
                </Float>
              </Select>
            </Selection>

          </PresentationControls>
        </group>

        <MicroscopeController meteorite={meteo} />

        <AudioController ref={audio_controller} scrollable_content={ref} />

        <ScrollObserver
          meteorite={meteo}
          micro_meteorite={micro_meteo}
          wall={white_wall}
          audio_controller={audio_controller}
        />
        <Intro meteorite={meteo} />
        <Scroll html className='viewports_wrapper'>
          <div className='viewport_wrapper' style={{ top: "0vh" }}>
            <h1>Debug: intro 1</h1>
          </div>

          <div className='viewport_wrapper' style={{ top: '100vh' }}>
            <h1>Debug: lyrics 2 </h1>
          </div>

          <div className='viewport_wrapper before_microscope' style={{ top: '200vh' }}>
            <h1>Debug: transition before microscope 3</h1>
          </div>

          <div className='viewport_wrapper microscope_section' style={{ top: '300vh' }}>
            <h1>Debug: microscope 4 and 5</h1>
          </div>

          <div className='viewport_wrapper credits' style={{ top: '500vh' }}>
            <h1>Debug: creadits 6</h1>

            <div className="top_right_credits_overlay">
              <p>SOURCE: Daniel Curucusi (Youtube)- Bolivia </p>
            </div>
            <CreditsOverlay />
          </div>

          <div className='viewport_wrapper sponsors' style={{ top: '600vh' }}>
            <div className="final_logo">
              <img src={'./res/sponsors/sponsors_logo.svg'} />
            </div>
            <div className="sponsors_text">
              <div>Made possible with the generous support of</div>
            </div>
            <div className="sponsor_logos">
              <img src={'./res/sponsors/sfci.svg'} />
              <img src={'./res/sponsors/ilewg.svg'} />
              <img src={'./res/sponsors/eesa.svg'} />
              <img src={'./res/sponsors/nl_film_fonds.svg'} />
              <img src={'./res/sponsors/idfa.svg'} />
              <img src={'./res/sponsors/afk.svg'} />
            </div>
          </div>

        </Scroll>
      </ScrollControls>
    </>
  )
});

export default ScrollableContent;