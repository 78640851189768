import React, { forwardRef } from 'react'

const WhiteWall = forwardRef(function (props, ref) {
    return (
        <>
            <mesh ref={ref}
                castShadow receiveShadow
                position={[0, 0, -5]}>
                <meshLambertMaterial color={"#D4D5DB"} opacity={0} transparent/>
                <boxGeometry args={[16, 16, 1]} />
            </mesh>
        </>
    );
});
export default WhiteWall;