import React, { useEffect } from 'react';
import { useFrame, useThree } from '@react-three/fiber';
import { useScroll } from '@react-three/drei'

// CHECK: https://greensock.com/forums/topic/25648-change-backgroundcolor-on-scroll/

export default function Mobile(props) {
    const s = useScroll();

    useEffect(() => {
        let root = document.querySelector("#root");
        root.style.overflowY = 'scroll';
    }, []);

    return (
        <div className='mobile_container'>
            <div className='mobile_intro_container'>
                <div className='mobile_intro'>
                    <div className='mobile_logo'>
                        <img src={'./res/top_left_logo_black.svg'} />
                    </div>
                    <div className='mobile_intro_background'>
                        <img src="./res/mobile/meteorite_mobile.png"></img>
                    </div>
                    {/* <p className="mobile_only_available">ONLY AVAILABLE ON DESKTOP</p> */}
                </div>
            </div>
            <div className='mobile_text'>
                <p>The Butterfly Effect navigates within the new geopolitical realities we live in and speculates upon a sensory experience of a territory only known through digital imageries. It suggests a future in which Humanity will be able to experience any kind of territory regardless of the spectrum of perception, time and geography, by enabling bystanders to physically engage with remote colonies.</p>
                <p>The work investigates an immersive form of design that can emulate interplanetary narratives, crossing over technology as archeology of our time, and what constitutes the DNA of any planet: geology.</p>
                <p>In an in-depth analysis of a one of a kind time capsule, The Butterfly Effect aims to recontextualize the technology driven approach to mapping and data visualization in favor of the Planetary. It engages a variety of experts and professionals in the fields of planetary science, aerospace engineering, fragrance technology and digital programming.</p>
                <p>The result is a multi-sensory installation in which visitors MEET a former shooting star. By means of various processes of data translation, the 4.5 billion years old meteorite reveals its hidden genetics through light, sound, smell and video while presenting different stages of its path from Space to Earth.</p>
                <p>* Use your desktop to explore more.</p>
            </div>
            <div className="mobile_sponsors_container">
                <p>THIS PROJECT IS MADE POSSIBLE WITH THE SUPPORT OF</p>
                <div className="mobile_sponsors">
                    <img src={'./res/sponsors/sfci.svg'} />
                    <img src={'./res/sponsors/ilewg.svg'} />
                    <img src={'./res/sponsors/eesa.svg'} />
                    <img src={'./res/sponsors/nl_film_fonds.svg'} />
                    <img src={'./res/sponsors/idfa.svg'} />
                    <img src={'./res/sponsors/afk.svg'} />
                </div>
            </div>
        </div>
    );
}