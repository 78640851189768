import React, { useEffect, useState, forwardRef } from 'react'
import { useGlobalState } from '../App';
import { Transition } from 'react-transition-group';
import { gsap } from 'gsap';
import { CustomEase } from "gsap/CustomEase";
gsap.registerPlugin(CustomEase);

function useForceUpdate() {
  const [value, setValue] = useState(0); // integer state
  return () => setValue(value => value + 1); // update state to force render
  // An function that increment 👆🏻 the previous state like here 
  // is better than directly setting `value + 1`
}

//export default function ChemicalScroll() {
const ChemicalScroll = forwardRef(function (props, ref) {
  const forceUpdate = useForceUpdate();

  const [scroll_pos] = useGlobalState('scroll_pos');
  const [isAtMicroscope] = useGlobalState('isAtMicroscope');
  const [viewportSize] = useGlobalState('viewport_size');

  const [chemical_index] = useGlobalState('chemical_index');
  const [selectedChemical, setSelected] = useState(-1);
  const [vertical_line_x, setVLineX] = useState(-1);

  const [lock_function] = useGlobalState('lock_scroll_function');
  const [scroll_descent] = useGlobalState('scroll_descent');
  const [, setDescentProgress] = useGlobalState('descent_progress');
  const [descent_y] = useState([0]);
  const [ongoing_descent, setOnGoingDescent] = useGlobalState('ongoing_descent');
  const [scroll_y, setScrollY] = useGlobalState('scroll_y');

  const DESCENT_LENGTH_PX = 200;
  const DESCENT_DURATION = 5;

  function setScrollLock(lock) {
    let lf = lock_function;
    if (lock) {
      document.querySelector('.App').addEventListener('wheel', lf, { passive: false });
    } else {
      document.querySelector('.App').removeEventListener('wheel', lf, { passive: false });
    }
    console.log("chemical scroll set scroll lock:", lock);
  }

  const meteorite_path = "./res/meteorites/";
  const struct_path = "./res/chemicals/";
  const chemical_info = [
    {
      formula: 'C nH 2n+1COOH',
      structure_file: 'carboxylic_acid_monomer.svg',
      common_name: 'carboxylic acid monomer',
      bond: '(bands 1760-1750)',
      description: "Often has strong sour odours, 'goaty' smells, locker room explaining the odour of Limburger cheese. The odor of vinegar is caused by the presence of acetic acid, a carboxylic acid, in the vinegar. The odor of ripe bananas and many other fruits is due to the presence of esters."
    },
    {
      formula: 'C8H8O2',
      structure_file: 'vinyl.svg',
      common_name: 'vinyl / phenyl ester',
      bond: '(bands 1780-1770)',
      description: "Odor Profile — very sweet, rosy-fruity, honey-like odor of moderate to poor tenacity. This compound is used in the flavor industry and in perfumes for its strong odor similar to honey. It occurs in brandy, capsicum, coffee, pepper, and some wine."
    },
    {
      formula: 'C5H8O',
      common_name: 'cyclopentanone',
      structure_file: 'cyclopentanone.svg',
      bond: '(bands 1725-1705)',
      description: "The aroma attributes of jasmine oil / peppermint-like smell. It is used as a chemical intermediate in making pharmaceuticals, insecticides and rubber products."
    },
    {
      formula: 'C4H6O3',
      structure_file: 'acetic_anhydride.svg',
      common_name: 'Acetic anhydride',
      bond: '(bands 1818-1775)',
      description: "Appears as a clear colorless liquid with strong vinegar odor. it is Used to make fibers, plastics, pharmaceuticals, dyes, and explosives."
    },
    {
      formula: 'RCHO',
      common_name: 'Aldehyde and ketone',
      structure_file: 'aldehydes_and_ketones.svg',
      bond: '(bands 1740-1720)',
      description: "Are known for their sweet and sometimes pungent odors. The odor from vanilla extract comes from the molecule vanillin. They provide a strong scent of almonds or rotten fruits. These compounds are common building blocks to make chemicals used in resins, perfumes (Chanel No. 5), dyes and detergents."
    },
    {
      formula: 'R3-xNHx',
      common_name: 'Amide',
      structure_file: 'amide.svg',
      bond: '(bands 1680-1630)',
      description: "Amines are organic derivatives of ammonia used in making azo-dyes and nylon. They are widely used in developing chemicals for medication such as paracetamol and water purification. It is commonly used in refineries and gas plants to improve safety, prevent corrosion and meet environmental regulations. Higher aliphatic amines smell like decaying fish."
    }
  ];

  //buttons
  const defaultStyleButtons = {
    transition: 'opacity 250ms ease-in-out',
    opacity: 0
  }
  const transitionsButtons = {
    entering: { opacity: 0, display: 'none' },
    entered: { opacity: 1, display: 'flex' },
    exiting: { opacity: 1, display: 'flex' },
    exited: { opacity: 0, display: 'none' }
  };

  //horizontal line
  const defaultStyleHorizontalLine = {
    transition: 'width 1000ms ease-in-out',
    width: '36px'
  }
  const transitionsHorizontalLine = {
    entering: { width: '36px' },
    entered: { width: viewportSize ? (viewportSize.width - 36) + 'px' : '100px' },
    exiting: { width: viewportSize ? (viewportSize.width - 36) + 'px' : '100px' },
    exited: { width: '36px' }
  };

  //vertical lines
  const defaultStyleVerticalLine = {
    transition: 'height 1000ms ease-in-out',
    height: '0px'
  }
  const transitionsVerticalLine = {
    entering: { height: '0px' },
    entered: { height: '100%' },
    exiting: { height: '100%' },
    exited: { height: '0px' }
  };

  //chemical information
  const defaultStyleChemicalInformation = {
    transition: 'opacity 1000ms ease-in-out',
    opacity: '0'
  }
  const transitionsChemicalInformation = {
    entering: { opacity: '0' },
    entered: { opacity: '1' },
    exiting: { opacity: '1' },
    exited: { opacity: '0' }
  };

  //meteorite images~
  const defaultStyleMeteorite = {
    transition: 'opacity 1000ms ease-in-out',
    opacity: '0'
  }
  const transitionsMeteorite = {
    entering: { opacity: '0' },
    entered: { opacity: '1' },
    exiting: { opacity: '1' },
    exited: { opacity: '0' }
  };


  useEffect(() => {
    if (isAtMicroscope && selectedChemical < 0) {
      selectChemical(chemical_index);
    }
    if (chemical_index !== selectedChemical) {
      selectChemical(chemical_index);
    }

    if (!ongoing_descent && scroll_descent && descent_y[0] === 0) {
      setOnGoingDescent(true);
      setScrollLock(true);
      gsap.timeline()
        .to(descent_y, {
          endArray: [DESCENT_LENGTH_PX],
          duration: DESCENT_DURATION,
          ease: CustomEase.create("custom", "M0,0 C0.4,0 0.393,0.296 0.5,0.5 0.606,0.702 0.6,1 1,1 "),
          onStart: function () { console.log("started descent"); },
          onUpdate: function () {
            forceUpdate();
            setDescentProgress(descent_y[0] / DESCENT_LENGTH_PX);
          },
          onComplete: function () {
            console.log("finished descent");
            setScrollLock(false);
            setOnGoingDescent(false);
          }
        });
      console.log("animating descent");
    }

    if (!ongoing_descent && !scroll_descent && descent_y[0] === DESCENT_LENGTH_PX) {
      setOnGoingDescent(true);
      setScrollLock(true);
      gsap.timeline()
        .to(descent_y, {
          endArray: [0],
          duration: DESCENT_DURATION,
          ease: CustomEase.create("custom", "M0,0 C0.4,0 0.393,0.296 0.5,0.5 0.606,0.702 0.6,1 1,1 "),
          onStart: function () { console.log("started rollback"); },
          onUpdate: function () {
            forceUpdate();
            setDescentProgress(descent_y[0] / DESCENT_LENGTH_PX);
          },
          onComplete: function () {
            console.log("finished rollback");
            setScrollLock(false);
            setOnGoingDescent(false);
          }
        });
      console.log("animating descent rollback");
    }
  });

  //called upon scroll
  function selectChemical(index) {
    if (index !== selectedChemical) {
      clearSelection();
    }

    setSelected(index);

    try {
      var buttons = document.querySelector('.chemicals_container').children;
    } catch (error) {
      console.warn('cannot find buttons');
      return;
    }

    buttons[index].click();
  }

  function clearSelection() {
    try {
      var buttons = document.querySelector('.chemicals_container').children;
    } catch (error) {
      console.warn('cannot find buttons');
      return;
    }

    for (let i = 0; i < buttons.length; i++) {
      const button = buttons[i];
      button.classList.remove('chemical_selected');
    }
  }

  //button click handler
  function handleChemClick(e) {
    clearSelection();

    //TODO update scroll on button click. tip: window.scrollTo(300, 500);

    var routine = setInterval(function () {
      let domrect = e.target.getBoundingClientRect();
      if (domrect.x !== 0) {
        clearInterval(routine);
        setVLineX(domrect.x + domrect.width / 2);
        e.target.classList.add('chemical_selected');
      }
    }, 10);
  }

  //a cada render, faz update aqui
  let elem, rect, line_y, buttons_y;
  try {
    elem = document.querySelector('.scrollbar_container');
    rect = elem.getBoundingClientRect();

    line_y = (scroll_pos * rect.height + rect.top + descent_y[0]) + 'px';
    buttons_y = (scroll_pos * rect.height + rect.top - 12 + DESCENT_LENGTH_PX) + 'px';

    setScrollY((parseFloat(line_y) - rect.top)/rect.height);

  } catch (e) {
    //console.error("scroll fez boom", e);
  }

  return (
    <>
      <Transition in={isAtMicroscope} timeout={1}>
        {state => (
          <div className="chemicals_container" style={{
            top: buttons_y,
            ...defaultStyleButtons,
            ...transitionsButtons[state]
          }}>
            <div className="chemical_button" onClick={(e) => handleChemClick(e)}>C nH 2n+1COOH</div>
            <div className="chemical_button" onClick={(e) => handleChemClick(e)}>C8H8O2</div>
            <div className="chemical_button" onClick={(e) => handleChemClick(e)}>C5H8O</div>
            <div className="chemical_button" onClick={(e) => handleChemClick(e)}>C4H6O3</div>
            <div className="chemical_button" onClick={(e) => handleChemClick(e)}>RCHO</div>
            <div className="chemical_button" onClick={(e) => handleChemClick(e)}>R3-xNHx</div>
          </div>)}
      </Transition>

      <Transition in={isAtMicroscope} timeout={1}>
        {state => (
          <div className='scroll_horizontal_line' style={{
            top: line_y,
            ...defaultStyleHorizontalLine,
            ...transitionsHorizontalLine[state]
          }}>
          </div>
        )}
      </Transition>

      <Transition in={isAtMicroscope} timeout={1}>
        {state => (
          <div className='scroll_vertical_line' style={{
            left: vertical_line_x,
            ...defaultStyleVerticalLine,
            ...transitionsVerticalLine[state]
          }}>
          </div>
        )}
      </Transition>

      <Transition in={isAtMicroscope} timeout={10}>
        {state => (
          <div className='chemical_information' style={{
            ...defaultStyleChemicalInformation,
            ...transitionsChemicalInformation[state]
          }}>
            <img className='structure' alt='' src={struct_path + chemical_info[Math.max(0, selectedChemical)].structure_file} />
            <span className='common_name'>{chemical_info[Math.max(0, selectedChemical)].common_name}</span>
            <span className='bond'>{chemical_info[Math.max(0, selectedChemical)].bond}</span>
            <p className='description'>{chemical_info[Math.max(0, selectedChemical)].description}</p>
          </div>
        )}
      </Transition>

      <Transition in={isAtMicroscope} timeout={10}>
        {state => (
          <div className='meteorite_image' style={{
            ...defaultStyleMeteorite,
            ...transitionsMeteorite[state]
          }}>
            <img alt='' src={meteorite_path + "meteorite_" + Math.max(0, selectedChemical) + ".png"} />
          </div>
        )}
      </Transition>
    </>
  );
});
export default ChemicalScroll;