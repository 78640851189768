/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import { useGLTF } from '@react-three/drei'
import React, { forwardRef } from 'react'


const MeteoriteColorful = forwardRef(function (props, ref) {
  const { nodes, materials } = useGLTF('./res/meteorites/colorful_surface.gltf');
  const scale = 33;

  return (
    <group dispose={null}>
      <group dispose={null} ref={ref} >
        <mesh
          receiveShadow
          castShadow
          material-opacity={0}
          position={props.position}
          geometry={nodes.Cube006.geometry}
          material={materials['Material.010']}
          rotation={[-Math.PI / 2, 0, 0]}
          scale={[scale, 1.2 * scale, 1.6 * scale]}
        />
        <mesh
          receiveShadow
          castShadow
          material-opacity={0}
          position={props.position}
          geometry={nodes.Cube006_1.geometry}
          material={materials['Material.011']}
          rotation={[-Math.PI / 2, 0, 0]}
          scale={[scale, 1.2 * scale, 1.6 * scale]}
        />
      </group>
    </group>
  )
});

export default MeteoriteColorful;

useGLTF.preload('./res/meteorites/colorful_surface.gltf')
