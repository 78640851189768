
import React, { useState, useEffect } from 'react';

function ScrollDownArrow(props) {
  return (
    <div className="scroll_down_arrow">
      <p>Scroll to continue</p>
      <img src={'./res/scroll_down_arrow.svg'} />
    </div>
  )
}

export default ScrollDownArrow