
import React, { forwardRef } from 'react';

const AudioOverlay = forwardRef(function (props, ref) {
  function toggleAudio(){
    console.log("toggle");
    props.scrollable_content.current.toggleAudio();
  }

  return (
    <>
      <div className="audio_player">
        <div id="equalizer" onClick={toggleAudio}>
          <canvas id='equalizer_canvas' width={32} height={32}></canvas>
        </div>        
      </div>
      <div className="audio_text"></div>
    </>
  )
});

export default AudioOverlay;