import React, { useRef, useEffect, useState } from 'react'
import { useGlobalState } from '../App';
import { useScroll, Html } from '@react-three/drei'
import { useFrame } from '@react-three/fiber'
import { EffectComposer, Selection, Select, Noise } from '@react-three/postprocessing'
import { gsap, } from 'gsap';

export default function IntroLogo() {
    return (
        <>
            <div className="intro_logo_wrapper">
                <div className="intro_logo">
                    <img src={'./res/intro_logo.svg'} />
                    <div className="intro_text">Dear stranger,<br /> at last on earth we meet</div>
                    <div className="intro_enter_container">
                        <div className="intro_enter_button">
                            Explore
                        </div>
                    </div>
                </div>
                {/* <div className="intro_bottom_text">Please use speakers or headphones</div> */}
            </div>
        </>
    );
}