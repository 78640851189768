/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import { useGLTF } from '@react-three/drei'
import React, { forwardRef, useState, useRef } from 'react'
import gsap from 'gsap';

const MeteoriteGlass = forwardRef( function(props, ref) {
  const { nodes, materials } = useGLTF('./res/meteorites/glass_surface.gltf');
  const scale = 43;

  // useFrame(() => {
  //   try {
  //     var debug_dom = document.getElementsByClassName("debug_meteo")[0];
  //     let p = ref.current.position;
  //     debug_dom.innerHTML = p.x +" "+ p.y +" "+ p.z;

  //     let s = ref.current.scale;
  //     debug_dom.innerHTML += "# "+ s.x +" "+ s.y +" "+ s.z;
  //   } catch(error){}
  // })

  return (
    <group dispose={null}>
      <mesh 
        ref={ref}
        receiveShadow
        castShadow
        material-transparent 
        material-opacity={props.opacity}
        position={props.position}

        geometry={nodes.Cube004.geometry} 
        material={materials['Material.003']} 
        rotation={[-Math.PI / 2, 0, 0]}

        //scale={[43.9662, 52.7594, 70.3459]}
        scale={[scale, 1.2*scale, 1.6*scale]}
        
        onPointerEnter={props.onPointerOver}
        onPointerOut={props.onPointerOut}
      />
    </group>
  )
});

export default MeteoriteGlass;

useGLTF.preload('./res/meteorites/glass_surface.gltf')
