import { gsap } from 'gsap';

class MyTypeWriter {
    constructor(target, content_name, final_cb) {
        this.QUICK_VERSION = false;

        if(this.QUICK_VERSION){
            console.warn("debug mode: typing very fast");
        }

        this.intro = ["Dear stranger,", "at last on earth we meet"];

        this.tbm_1 = ["SpectraSuite Data File ++++++++++++++++++++++++++++++++++++"]
        this.tbm_2 = [
            "Date: Mon Apr 11 09:48:32 CEST 2022",
            "User: TUD200349",
            "Dark Spectrum Present: Yes",
            "Reference Spectrum Present: Yes",
            "Number of Sampled Component Spectra: 1 Spectrometers: MAYP10046",
            "Integration Time (usec): 360998 (MAYP10046)",
            "Spectra Averaged: 4 (MAYP10046)",
            "Boxcar Smoothing: 0 (MAYP10046)",
            "Correct for Electrical Dark: No (MAYP10046)",
            "Strobe/Lamp Enabled: No (MAYP10046)",
            "Correct for Detector Non-linearity: No (MAYP10046)",
            "Correct for Stray Light: No (MAYP10046)",
            "Number of Pixels in Processed Spectrum: 2068",
            ">>>>>",
            ">>>>>",
            ">>>>>",
            ">>>>>",
            ">>>>>Begin Processed Spectral Data<<<<< " ];
        this.tbm_3 = [
            "196,88 15,816",
            "197,36 -153,125",
            "197,83 -73,810",
            "198,31 22,855",
            "198,79 28,543",
            "199,26 3,042",
            "199,74 9,778",
            "200,21 14,340",
            "200,69 4,781",
            "201,17 20,076",
            "201,64 20,818",
            "202,12 7,762",
            "202,59 18,094",
            "203,07 13,545",
            "203,55 14,824",
            "204,02 22,161",
            "204,50 11,151",
            "204,97 14,904",
            "205,45 14,603",
            "205,93 16,010",
            "206,40 11,521",
            "206,88 12,448",
            "207,35 10,633",
            "207,83 14,484",
            "208,30 1,905",
            "208,78 15,753",
            "209,26 17,866",
            "209,73 28,685",
            "210,21 7,195",
            "210,68 13,114",
            "211,16 1,111",
            "211,63 19,614",
            "212,11 23,315",
            "212,59 4,489",
            "213,06 23,380",
            "213,54 2,794",
            "214,01 14,764",
            "214,49 0,301",
            "214,96 11,303",
            "215,44 16,159",
            "215,91 4,540",
            "216,39 15,093",
            "216,86 16,340",
            "217,34 5,827",
            "217,81 11,815",
            "218,29 14,671",
            "218,76 19,002",
            "219,24 18,450",
            "219,71 9,987",
            "220,19 27,796",
            "220,66 19,399",
            "221,14 24,219",
            "221,61 18,024",
            "222,09 15,133",
            "222,56 4,252",
            "223,04 7,342",
            "223,51 23,832",
            "223,99 11,158",
            "224,46 10,156",
            "224,94 19,444",
            "225,41 1,360",
            "225,89 12,468",
            "226,36 15,084",
            "226,84 14,471",
            "227,31 16,084",
            "227,79 15,803",
            "228,26 14,844",
            "228,74 -3,868",
            "229,21 19,770",
            "229,68 20,665",
            "230,16 6,662",
            "230,63 18,966",
            "231,11 14,513",
            "231,58 4,799",
            "232,06 14,955",
            "232,53 17,936",
            "233,01 14,246",
            "233,48 14,000",
            "233,95 10,294",
            "234,43 41,067",
            "234,90 -15,392",
            "235,38 10,128",
            "235,85 8,404",
            "236,32 16,617",
            "236,80 31,604",
            "237,27 -3,013",
            "237,75 17,950",
            "238,22 18,011",
            "238,69 29,363",
            "239,17 24,929",
            "239,64 -2,520",
            "240,12 8,989",
            "240,59 -5,045",
            "241,06 -18,646",
            "241,54 -2,716",
            "242,01 19,242",
            "242,49 5,290",
            "242,96 37,646",
            "243,43 3,201",
            "243,91 -10,227",
            "244,38 8,333",
            "244,85 -24,787",
            "245,33 13,729",
            "245,80 15,311",
            "246,27 7,680",
            "246,75 19,201",
            "247,22 1,515",
            "247,70 21,835",
            "248,17 11,458",
            "248,64 3,841",
            "249,12 14,392",
            "249,59 10,812",
            "250,06 7,353",
            "250,54 17,336",
            "251,01 18,950",
            "251,48 15,370",
            "251,96 23,188",
            "252,43 10,757",
            "252,90 22,063",
            "253,37 27,049",
            "253,85 -7,423",
            "254,32 31,210",
            "254,79 18,011",
            "255,27 25,000",
            "255,74 2,219",
            "256,21 27,624",
            "256,69 -14,231",
            "257,16 15,087",
            "257,63 36,207",
            "258,10 1,794",
            "258,58 9,254",
            "259,05 14,450",
            "259,52 0,727",
            "260,00 9,741",
            "260,47 -2,023",
            "260,94 21,570",
            "261,41 8,795",
            "261,89 11,852",
            "262,36 5,081",
            "262,83 11,242",
            "263,30 10,934",
            "263,78 19,355",
            "264,25 19,182",
            "264,72 28,996",
            "265,19 32,774",
            "265,67 21,667",
            "266,14 19,655",
            "266,61 19,831",
            "267,08 5,459",
            "267,56 -10,456",
            "268,03 -0,205",
            "268,50 6,884",
            "268,97 8,333",
            "269,45 35,417",
            "269,92 8,801",
            "270,39 10,185",
            "270,86 13,054",
            "271,33 8,210",
            "271,81 6,926",
            "272,28 2,764",
            "272,75 -9,746",
            "273,22 0,998",
            "273,69 19,433",
            "274,17 20,650",
            "274,64 7,717",
            "275,11 7,770",
            "275,58 11,894",
            "276,05 12,783",
            "276,53 12,798",
            "277,00 34,407",
            "277,47 2,937",
            "277,94 30,835",
            "278,41 16,229",
            "278,88 13,917",
            "279,36 8,583",
            "279,83 21,550",
            "280,30 13,154",
            "280,77 13,913",
            "281,24 26,277",
            "281,71 18,481",
            "282,18 11,748",
            "282,66 -14,590",
            "283,13 -5,775",
            "283,60 5,522",
            "284,07 17,064",
            "284,54 25,696",
            "285,01 -5,588",
            "285,48 38,604",
            "285,96 13,408",
            "286,43 9,805",
            "286,90 7,050",
            "287,37 13,740",
            "287,84 8,834",
            "288,31 -7,872",
            "288,78 30,519",
            "289,25 12,500",
            "289,72 24,125",
            "290,20 3,552",
            "290,67 20,920",
            "291,14 8,154",
            "291,61 22,404",
            "292,08 27,450",
            "292,55 12,716",
            "293,02 -4,891",
            "293,49 21,707",
            "293,96 11,111",
            "294,43 -9,722",
            "294,90 23,232",
            "295,37 -0,267",
            "295,85 28,647",
            "296,32 -1,289",
            "296,79 -17,448",
            "297,26 21,528",
            "297,73 9,078",
            "298,20 9,535",
            "298,67 6,541",
            "299,14 5,506",
            "299,61 21,381",
            "300,08 13,746",
            "300,55 12,189",
            "301,02 2,273",
            "301,49 -14,735",
            "301,96 -13,546",
            "302,43 2,046",
            "302,90 18,059",
            "303,37 11,396",
            "303,84 12,075",
            "304,31 1,267",
            "304,78 20,295",
            "305,25 5,231",
            "305,72 9,308",
            "306,19 3,910",
            "306,66 18,007",
            "307,13 8,333",
            "307,60 8,110",
            "308,07 6,492",
            "308,54 7,801",
            "309,01 5,526",
            "309,48 6,356",
            "309,95 4,187",
            "310,42 15,242",
            "310,89 8,243",
            "311,36 15,508",
            "311,83 -4,339",
            "312,30 -0,457",
            "312,77 19,628",
            "313,24 17,836",
            "313,71 15,653",
            "314,18 -15,194",
            "314,65 9,257",
            "315,12 6,464",
            "315,59 -0,570",
            "316,06 36,064",
            "316,53 8,665",
            "317,00 17,655",
            "317,47 -2,810",
            "317,94 -5,576",
            "318,41 22,894",
            "318,87 26,502",
            "319,34 7,210",
            "319,81 19,178",
            "320,28 8,333",
            "320,75 11,312",
            "321,22 21,538",
            "321,69 20,602",
            "322,16 9,524",
            "322,63 3,947",
            "323,10 -17,795",
            "323,57 16,978",
            "324,04 13,985",
            "324,50 24,571",
            "324,97 11,449",
            "325,44 16,353",
            "325,91 -0,310",
            "326,38 23,705",
            "326,85 -8,146",
            "327,32 3,960",
            "327,79 24,784",
            "328,26 -12,500",
            "328,72 1,144",
            "329,19 1,404",
            "329,66 18,069",
            "330,13 8,182",
            "330,60 6,826",
            "331,07 27,947",
            "331,54 9,574",
            "332,00 32,665",
            "332,47 31,902",
            "332,94 13,087",
            "333,41 0,216",
            "333,88 9,568",
            "334,35 32,258",
            "334,82 25,951",
            "335,28 -5,783",
            "335,75 0,085",
            "336,22 -6,349",
            "336,69 25,571",
            "337,16 9,663",
            "337,62 -2,872",
            "338,09 15,013",
            "338,56 13,229",
            "339,03 12,315",
            "339,50 20,588",
            "339,97 -1,903",
            "340,43 5,904",
            "340,90 20,020",
            "341,37 -3,103",
            "341,84 16,711",
            "342,31 13,487",
            "342,77 19,815",
            "343,24 9,692",
            "343,71 30,547",
            "344,18 22,230",
            "344,64 2,120",
            "345,11 12,322",
            "345,58 -5,816",
            "346,05 5,929",
            "346,52 22,704",
            "346,98 -0,179",
            "347,45 30,339",
            "347,92 3,465",
            "348,39 -5,961",
            "348,85 15,066",
            "349,32 20,070",
            "349,79 16,505",
            "350,26 17,458",
            "350,72 6,343",
            "351,19 1,176",
            "351,66 4,545",
            "352,13 21,288",
            "352,59 19,528",
            "353,06 20,477",
            "353,53 4,130",
            "354,00 5,206",
            "354,46 15,735",
            "354,93 19,685",
            "355,40 5,461",
            "355,86 21,893",
            "356,33 2,202",
            "356,80 11,327",
            "357,27 10,180",
            "357,73 20,295",
            "358,20 10,609",
            "358,67 24,694",
            "359,13 13,065",
            "359,60 7,176",
            "360,07 -4,663",
            "360,53 13,406",
            "361,00 18,302",
            "361,47 19,767",
            "361,93 8,603",
            "362,40 8,635",
            "362,87 8,847",
            "363,33 13,341",
            "363,80 9,048",
            "364,27 16,384",
            "364,73 5,882",
            "365,20 15,451",
            "365,67 14,826",
            "366,13 8,732",
            "366,60 11,673",
            "367,07 7,500",
            "367,53 7,824",
            "368,00 15,817",
            "368,47 15,322",
            "368,93 16,658",
            "369,40 0,076",
            "369,87 16,153",
            "370,33 11,624",
            "370,80 9,520",
            "371,26 12,038",
            "371,73 9,781",
            "372,20 8,849",
            "372,66 6,218",
            "373,13 12,910",
            "373,59 11,580",
            "374,06 9,978",
            "374,53 13,851",
            "374,99 10,657",
            "375,46 13,472",
            "375,92 10,894",
            "376,39 8,752",
            "376,86 11,589",
            "377,32 9,841",
            "377,79 15,810",
            "378,25 6,941",
            "378,72 11,138",
            "379,19 9,609",
            "379,65 11,860",
            "380,12 11,045",
            "380,58 13,023",
            "381,05 10,677",
            "381,51 13,461",
            "381,98 13,768",
            "382,45 10,635",
            "382,91 11,840",
            "383,38 11,799",
            "383,84 12,946",
            "384,31 13,870",
            "384,77 10,744",
            "385,24 8,545",
            "385,70 8,445",
            "386,17 13,899",
            "386,63 10,962",
            "387,10 12,458",
            "387,56 10,705",
            "388,03 12,356",
            "388,49 10,729",
            "388,96 7,359",
            "389,43 13,240",
            "389,89 9,046",
            "390,36 11,872",
            "390,82 9,503",
            "391,29 11,900",
            "391,75 11,177",
            "392,22 10,930",
            "392,68 7,675",
            "393,15 10,603",
            "393,61 10,285",
            "394,08 10,674",
            "394,54 13,157",
            "395,00 9,841",
            "395,47 9,826",
            "395,93 11,317",
            "396,40 9,972",
            "396,86 10,022",
            "397,33 11,674",
            "397,79 10,301",
            "398,26 10,349",
            "398,72 12,183",
            "399,19 10,637",
            "399,65 11,748",
            "400,12 10,728",
            "400,58 8,380",
            "401,04 12,283",
            "401,51 10,404",
            "401,97 11,114",
            "402,44 11,460",
            "402,90 10,826",
            "403,37 10,870",
            "403,83 8,321",
            "404,30 11,486",
            "404,76 10,203",
            "405,22 9,872",
            "405,69 9,737",
            "406,15 10,113",
            "406,62 10,291",
            "407,08 9,318",
            "407,54 10,620",
            "408,01 9,624",
            "408,47 9,493",
            "408,94 10,672",
            "409,40 9,307",
            "409,86 9,383",
            "410,33 10,393",
            "410,79 9,960",
            "411,26 10,858",
            "411,72 9,999",
            "412,18 9,641",
            "412,65 9,861",
            "413,11 9,616",
            "413,57 10,152",
            "414,04 9,677",
            "414,50 9,340",
            "414,97 9,573",
            "415,43 10,176",
            "415,89 11,204",
            "416,36 9,860",
            "416,82 11,310",
            "417,28 9,456",
            "417,75 10,400",
            "418,21 10,169",
            "418,67 10,899",
            "419,14 9,636",
            "419,60 9,632",
            "420,06 10,202",
            "420,53 10,594",
            "420,99 9,046",
            "421,45 9,728",
            "421,92 9,965",
            "422,38 9,274",
            "422,84 10,553",
            "423,31 10,372",
            "423,77 10,186",
            "424,23 9,137",
            "424,69 8,684",
            "425,16 10,164",
            "425,62 10,241",
            "426,08 10,129",
            "426,55 10,358",
            "427,01 9,069",
            "427,47 10,596",
            "427,93 10,371",
            "428,40 9,626",
            "428,86 9,918",
            "429,32 9,482",
            "429,79 10,096",
            "430,25 9,508",
            "430,71 10,228",
            "431,17 9,301",
            "431,64 10,521",
            "432,10 10,208",
            "432,56 10,560",
            "433,02 9,317",
            "433,49 10,444",
            "433,95 10,154",
            "434,41 9,472",
            "434,87 9,798",
            "435,34 9,576",
            "435,80 9,655",
            "436,26 8,565",
            "436,72 9,763",
            "437,19 10,239",
            "437,65 9,435",
            "438,11 10,293",
            "438,57 10,377",
            "439,03 10,044",
            "439,50 9,776",
            "439,96 10,448",
            "440,42 9,356",
            "440,88 9,941",
            "441,34 9,971",
            "441,81 9,563",
            "442,27 10,177",
            "442,73 9,714",
            "443,19 9,918",
            "443,65 10,439",
            "444,12 9,751",
            "444,58 9,866",
            "445,04 9,371",
            "445,50 10,386",
            "445,96 9,453",
            "446,42 10,258",
            "446,89 9,923",
            "447,35 9,802",
            "447,81 9,514",
            "448,27 9,385",
            "448,73 9,772",
            "449,19 9,582",
            "449,66 9,738",
            "450,12 9,684",
            "450,58 10,159",
            "451,04 10,047",
            "451,50 10,297",
            "451,96 9,616",
            "452,42 10,236",
            "452,89 9,670",
            "453,35 10,057",
            "453,81 10,022",
            "454,27 9,684",
            "454,73 10,154",
            "455,19 10,183",
            "455,65 10,016",
            "456,11 9,542",
            "456,58 9,893",
            "457,04 10,155",
            "457,50 9,859",
            "457,96 10,102",
            "458,42 10,035",
            "458,88 9,598",
            "459,34 10,124",
            "459,80 9,819",
            "460,26 10,017",
            "460,72 10,295",
            "461,18 9,900",
            "461,65 10,000",
            "462,11 9,936",
            "462,57 9,703",
            "463,03 9,846",
            "463,49 9,700",
            "463,95 9,912",
            "464,41 10,073",
            "464,87 10,148",
            "465,33 10,086",
            "465,79 9,621",
            "466,25 10,087",
            "466,71 9,864",
            "467,17 10,105",
            "467,63 9,889",
            "468,09 9,904",
            "468,55 9,889",
            "469,01 9,927",
            "469,47 10,030",
            "469,93 10,031",
            "470,39 9,910",
            "470,85 10,024",
            "471,32 10,014",
            "471,78 10,102",
            "472,24 9,631",
            "472,70 9,990",
            "473,16 10,107",
            "473,62 9,908",
            "474,08 10,001",
            "474,54 9,916",
            "475,00 9,844",
            "475,46 9,655",
            "475,92 9,817",
            "476,38 9,570",
            "476,84 9,932",
            "477,30 9,721",
            "477,75 9,887",
            "478,21 10,036",
            "478,67 10,085",
            "479,13 9,679",
            "479,59 9,978",
            "480,05 9,725",
            "480,51 10,020",
            "480,97 10,057",
            "481,43 9,717",
            "481,89 9,902",
            "482,35 10,176",
            "482,81 9,976",
            "483,27 9,786",
            "483,73 9,974",
            "484,19 9,980",
            "484,65 9,856",
            "485,11 10,047",
            "485,57 10,088",
            "486,03 9,944",
            "486,49 10,189",
            "486,94 9,826",
            "487,40 10,058",
            "487,86 9,919",
            "488,32 9,899",
            "488,78 9,960",
            "489,24 10,055",
            "489,70 9,972",
            "490,16 9,874",
            "490,62 9,688",
            "491,08 9,898",
            "491,54 10,252",
            "491,99 9,687",
            "492,45 9,963",
            "492,91 10,129",
            "493,37 9,954",
            "493,83 9,905",
            "494,29 10,020",
            "494,75 10,099",
            "495,21 9,950",
            "495,66 9,796",
            "496,12 9,828",
            "496,58 10,054",
            "497,04 9,952",
            "497,50 10,143",
            "497,96 10,115",
            "498,42 10,202",
            "498,87 9,961",
            "499,33 10,137",
            "499,79 10,065",
            "500,25 10,011",
            "500,71 10,233",
            "501,17 10,203",
            "501,63 10,060",
            "502,08 10,277",
            "502,54 10,312",
            "503,00 10,341",
            "503,46 10,040",
            "503,92 10,125",
            "504,37 10,430",
            "504,83 10,152",
            "505,29 10,218",
            "505,75 10,194",
            "506,21 10,228",
            "506,66 10,148",
            "507,12 10,180",
            "507,58 10,391",
            "508,04 10,155",
            "508,50 10,353",
            "508,95 10,353",
            "509,41 10,334",
            "509,87 10,262",
            "510,33 10,386",
            "510,79 10,024",
            "511,24 10,344",
            "511,70 10,279",
            "512,16 10,282",
            "512,62 10,223",
            "513,07 10,465",
            "513,53 10,326",
            "513,99 10,470",
            "514,45 10,416",
            "514,90 10,316",
            "515,36 10,429",
            "515,82 10,454",
            "516,28 10,339",
            "516,73 10,312",
            "517,19 10,457",
            "517,65 10,442",
            "518,11 10,575",
            "518,56 10,542",
            "519,02 10,652",
            "519,48 10,494",
            "519,94 10,388",
            "520,39 10,287",
            "520,85 10,474",
            "521,31 10,482",
            "521,76 10,668",
            "522,22 10,602",
            "522,68 10,673",
            "523,13 9,767",
            "523,59 10,497",
            "524,05 10,477",
            "524,51 10,669",
            "524,96 10,545",
            "525,42 10,572",
            "525,88 10,460",
            "526,33 10,581",
            "526,79 10,612",
            "527,25 10,624",
            "527,70 10,651",
            "528,16 10,679",
            "528,62 10,730",
            "529,07 10,764",
            "529,53 10,702",
            "529,99 10,677",
            "530,44 10,789",
            "530,90 10,786",
            "531,36 10,839",
            "531,81 10,925",
            "532,27 10,803",
            "532,73 10,786",
            "533,18 10,863",
            "533,64 10,933",
            "534,09 10,792",
            "534,55 10,808",
            "535,01 10,718",
            "535,46 10,850",
            "535,92 10,955",
            "536,38 10,950",
            "536,83 10,719",
            "537,29 10,845",
            "537,74 10,850",
            "538,20 10,877",
            "538,66 10,988",
            "539,11 10,762",
            "539,57 10,965",
            "540,02 11,127",
            "540,48 11,039",
            "540,94 10,918",
            "541,39 11,090",
            "541,85 11,223",
            "542,30 11,105",
            "542,76 10,986",
            "543,21 11,138",
            "543,67 11,094",
            "544,13 11,175",
            "544,58 11,171",
            "545,04 11,081",
            "545,49 11,209",
            "545,95 11,047",
            "546,40 11,157",
            "546,86 11,229",
            "547,31 11,068",
            "547,77 11,279",
            "548,23 11,296",
            "548,68 11,287",
            "549,14 11,450",
            "549,59 11,372",
            "550,05 11,322",
            "550,50 11,175",
            "550,96 11,334",
            "551,41 11,369",
            "551,87 11,290",
            "552,32 11,293",
            "552,78 11,472",
            "553,23 11,227",
            "553,69 11,372",
            "554,14 11,570",
            "554,60 11,358",
            "555,05 11,414",
            "555,51 11,503",
            "555,96 11,304",
            "556,42 11,619",
            "556,87 11,524",
            "557,33 11,641",
            "557,78 11,616",
            "558,24 11,573",
            "558,69 11,648",
            "559,15 11,564",
            "559,60 11,578",
            "560,06 11,523",
            "560,51 11,672",
            "560,97 11,678",
            "561,42 11,787",
            "561,88 11,674",
            "562,33 11,695",
            "562,78 11,769",
            "563,24 11,707",
            "563,69 11,693",
            "564,15 11,717",
            "564,60 11,749",
            "565,06 11,708",
            "565,51 11,744",
            "565,97 11,857",
            "566,42 11,800",
            "566,87 11,823",
            "567,33 11,713",
            "567,78 11,861",
            "568,24 11,916",
            "568,69 11,896",
            "569,15 11,829",
            "569,60 11,766",
            "570,05 11,933",
            "570,51 11,890",
            "570,96 11,828",
            "571,42 11,928",
            "571,87 12,056",
            "572,32 12,001",
            "572,78 11,963",
            "573,23 11,962",
            "573,68 12,047",
            "574,14 12,001",
            "574,59 12,143",
            "575,05 12,039",
            "575,50 12,158",
            "575,95 12,091",
            "576,41 12,154",
            "576,86 12,152",
            "577,31 12,084",
            "577,77 12,316",
            "578,22 12,132",
            "578,68 12,203",
            "579,13 12,113",
            "579,58 12,238",
            "580,04 12,214",
            "580,49 12,212",
            "580,94 12,407",
            "581,40 12,160",
            "581,85 12,332",
            "582,30 12,333",
            "582,76 12,210",
            "583,21 12,277",
            "583,66 12,243",
            "584,12 12,122",
            "584,57 12,354",
            "585,02 12,332",
            "585,47 12,381",
            "585,93 12,319",
            "586,38 12,408",
            "586,83 12,325",
            "587,29 12,428",
            "587,74 12,422",
            "588,19 12,562",
            "588,65 12,481",
            "589,10 12,383",
            "589,55 12,576",
            "590,00 12,414",
            "590,46 12,644",
            "590,91 12,394",
            "591,36 12,557",
            "591,81 12,571",
            "592,27 12,470",
            "592,72 12,643",
            "593,17 12,647",
            "593,63 12,495",
            "594,08 12,626",
            "594,53 12,521",
            "594,98 12,566",
            "595,44 12,554",
            "595,89 12,770",
            "596,34 12,728",
            "596,79 12,640",
            "597,25 12,771",
            "597,70 12,672",
            "598,15 12,788",
            "598,60 12,570",
            "599,05 12,823",
            "599,51 12,799",
            "599,96 12,838",
            "600,41 12,648",
            "600,86 12,688",
            "601,31 12,870",
            "601,77 12,841",
            "602,22 12,740",
            "602,67 12,808",
            "603,12 12,942",
            "603,58 12,951",
            "604,03 12,845",
            "604,48 12,870",
            "604,93 12,911",
            "605,38 12,852",
            "605,83 13,015",
            "606,29 13,038",
            "606,74 12,793",
            "607,19 12,996",
            "607,64 12,908",
            "608,09 12,911",
            "608,54 12,936",
            "609,00 12,944",
            "609,45 12,962",
            "609,90 13,055",
            "610,35 12,940",
            "610,80 13,007",
            "611,25 12,973",
            "611,71 13,065",
            "612,16 13,094",
            "612,61 13,081",
            "613,06 12,994",
            "613,51 13,141",
            "613,96 13,053",
            "614,41 13,167",
            "614,86 13,116",
            "615,32 13,131",
            "615,77 13,079",
            "616,22 13,046",
            "616,67 13,149",
            "617,12 13,120",
            "617,57 12,975",
            "618,02 13,194",
            "618,47 13,200",
            "618,92 13,254",
            "619,38 13,168",
            "619,83 13,285",
            "620,28 13,202",
            "620,73 13,174",
            "621,18 13,225",
            "621,63 13,215",
            "622,08 13,161",
            "622,53 13,241",
            "622,98 13,144",
            "623,43 13,341",
            "623,88 13,335",
            "624,33 13,317",
            "624,78 13,242",
            "625,24 13,291",
            "625,69 13,352",
            "626,14 13,400",
            "626,59 13,344",
            "627,04 13,398",
            "627,49 13,406",
            "627,94 13,373",
            "628,39 13,415",
            "628,84 13,398",
            "629,29 13,314",
            "629,74 13,353",
            "630,19 13,421",
            "630,64 13,345",
            "631,09 13,373",
            "631,54 13,403",
            "631,99 13,518",
            "632,44 13,390",
            "632,89 13,388",
            "633,34 13,394",
            "633,79 13,405",
            "634,24 13,517",
            "634,69 13,461",
            "635,14 13,500",
            "635,59 13,441",
            "636,04 13,494",
            "636,49 13,570",
            "636,94 13,584",
            "637,39 13,502",
            "637,84 13,540",
            "638,29 13,481",
            "638,74 13,495",
            "639,19 13,498",
            "639,64 13,588",
            "640,09 13,591",
            "640,54 13,604",
            "640,99 13,579",
            "641,44 13,521",
            "641,89 13,680",
            "642,34 13,620",
            "642,79 13,602",
            "643,23 13,560",
            "643,68 13,607",
            "644,13 13,656",
            "644,58 13,645",
            "645,03 13,594",
            "645,48 13,597",
            "645,93 13,708",
            "646,38 13,586",
            "646,83 13,670",
            "647,28 13,776",
            "647,73 13,757",
            "648,18 13,678",
            "648,63 13,648",
            "649,07 13,764",
            "649,52 13,720",
            "649,97 13,714",
            "650,42 13,753",
            "650,87 13,771",
            "651,32 13,782",
            "651,77 13,794",
            "652,22 13,789",
            "652,67 13,617",
            "653,11 13,808",
            "653,56 13,783",
            "654,01 13,768",
            "654,46 13,763",
            "654,91 13,762",
            "655,36 13,765",
            "655,81 13,795",
            "656,26 13,918",
            "656,70 13,765",
            "657,15 13,860",
            "657,60 13,831",
            "658,05 13,763",
            "658,50 13,793",
            "658,95 13,829",
            "659,39 13,893",
            "659,84 13,766",
            "660,29 13,810",
            "660,74 13,835",
            "661,19 13,917",
            "661,64 13,788",
            "662,08 13,842",
            "662,53 13,808",
            "662,98 13,921",
            "663,43 13,858",
            "663,88 13,899",
            "664,33 13,936",
            "664,77 13,863",
            "665,22 13,967",
            "665,67 13,855",
            "666,12 13,907",
            "666,57 13,990",
            "667,01 13,968",
            "667,46 13,956",
            "667,91 13,954",
            "668,36 14,085",
            "668,80 13,995",
            "669,25 13,998",
            "669,70 13,910",
            "670,15 14,036",
            "670,60 13,937",
            "671,04 13,875",
            "671,49 13,972",
            "671,94 14,124",
            "672,39 14,043",
            "672,83 14,044",
            "673,28 13,972",
            "673,73 14,056",
            "674,18 14,003",
            "674,62 13,946",
            "675,07 14,073",
            "675,52 14,115",
            "675,96 13,951",
            "676,41 14,070",
            "676,86 14,116",
            "677,31 14,153",
            "677,75 14,123",
            "678,20 14,181",
            "678,65 14,039",
            "679,10 14,081",
            "679,54 14,079",
            "679,99 14,089",
            "680,44 14,234",
            "680,88 13,956",
            "681,33 14,179",
            "681,78 14,133",
            "682,22 14,119",
            "682,67 14,060",
            "683,12 14,109",
            "683,56 14,185",
            "684,01 14,045",
            "684,46 14,040",
            "684,91 14,115",
            "685,35 14,135",
            "685,80 14,184",
            "686,25 14,144",
            "686,69 14,092",
            "687,14 14,056",
            "687,58 14,172",
            "688,03 14,097",
            "688,48 14,162",
            "688,92 14,095",
            "689,37 14,166",
            "689,82 14,111",
            "690,26 14,195",
            "690,71 14,195",
            "691,16 14,083",
            "691,60 14,212",
            "692,05 14,241",
            "692,50 14,199",
            "692,94 14,219",
            "693,39 14,236",
            "693,83 14,216",
            "694,28 14,146",
            "694,73 14,162",
            "695,17 14,202",
            "695,62 14,235",
            "696,06 14,307",
            "696,51 14,265",
            "696,96 14,149",
            "697,40 14,320",
            "697,85 14,237",
            "698,29 14,176",
            "698,74 14,255",
            "699,19 14,252",
            "699,63 14,226",
            "700,08 14,282",
            "700,52 14,322",
            "700,97 14,327",
            "701,41 14,277",
            "701,86 14,300",
            "702,31 14,273",
            "702,75 14,273",
            "703,20 14,256",
            "703,64 14,214",
            "704,09 14,263",
            "704,53 14,332",
            "704,98 14,331",
            "705,42 14,344",
            "705,87 14,236",
            "706,31 14,297",
            "706,76 14,275",
            "707,21 14,276",
            "707,65 14,369",
            "708,10 14,375",
            "708,54 14,279",
            "708,99 14,318",
            "709,43 14,407",
            "709,88 14,294",
            "710,32 14,322",
            "710,77 14,339",
            "711,21 14,239",
            "711,66 14,297",
            "712,10 14,263",
            "712,55 14,300",
            "712,99 14,451",
            "713,44 14,339",
            "713,88 14,322",
            "714,33 14,390",
            "714,77 14,348",
            "715,22 14,358",
            "715,66 14,421",
            "716,10 14,424",
            "716,55 14,413",
            "716,99 14,396",
            "717,44 14,283",
            "717,88 14,400",
            "718,33 14,411",
            "718,77 14,513",
            "719,22 14,345",
            "719,66 14,254",
            "720,11 14,398",
            "720,55 14,400",
            "720,99 14,287",
            "721,44 14,336",
            "721,88 14,480",
            "722,33 14,329",
            "722,77 14,454",
            "723,22 14,359",
            "723,66 14,339",
            "724,10 14,338",
            "724,55 14,323",
            "724,99 14,338",
            "725,44 14,346",
            "725,88 14,316",
            "726,33 14,289",
            "726,77 14,394",
            "727,21 14,392",
            "727,66 14,385",
            "728,10 14,458",
            "728,54 14,481",
            "728,99 14,453",
            "729,43 14,432",
            "729,88 14,382",
            "730,32 14,334",
            "730,76 14,357",
            "731,21 14,428",
            "731,65 14,449",
            "732,10 14,371",
            "732,54 14,417",
            "732,98 14,431",
            "733,43 14,412",
            "733,87 14,403",
            "734,31 14,481",
            "734,76 14,372",
            "735,20 14,415",
            "735,64 14,316",
            "736,09 14,438",
            "736,53 14,348",
            "736,97 14,470",
            "737,42 14,357",
            "737,86 14,413",
            "738,30 14,504",
            "738,75 14,352",
            "739,19 14,452",
            "739,63 14,485",
            "740,08 14,195",
            "740,52 14,368",
            "740,96 14,383",
            "741,41 14,434",
            "741,85 14,427",
            "742,29 14,379",
            "742,73 14,396",
            "743,18 14,347",
            "743,62 14,461",
            "744,06 14,440",
            "744,51 14,475",
            "744,95 14,488",
            "745,39 14,390",
            "745,83 14,392",
            "746,28 14,421",
            "746,72 14,429",
            "747,16 14,442",
            "747,60 14,475",
            "748,05 14,469",
            "748,49 14,474",
            "748,93 14,424",
            "749,38 14,452",
            "749,82 14,461",
            "750,26 14,432",
            "750,70 14,497",
            "751,14 14,345",
            "751,59 14,496",
            "752,03 14,475",
            "752,47 14,446",
            "752,91 14,430",
            "753,36 14,413",
            "753,80 14,350",
            "754,24 14,418",
            "754,68 14,488",
            "755,13 14,451",
            "755,57 14,379",
            "756,01 14,509",
            "756,45 14,503",
            "756,89 14,457",
            "757,34 14,469",
            "757,78 14,522",
            "758,22 14,345",
            "758,66 14,424",
            "759,10 14,388",
            "759,55 14,405",
            "759,99 14,369",
            "760,43 14,357",
            "760,87 14,399",
            "761,31 14,424",
            "761,75 14,435",
            "762,20 14,335",
            "762,64 14,373",
            "763,08 14,485",
            "763,52 14,451",
            "763,96 14,466",
            "764,40 14,383",
            "764,84 14,300",
            "765,29 14,405",
            "765,73 14,397",
            "766,17 14,488",
            "766,61 14,350",
            "767,05 14,292",
            "767,49 14,340",
            "767,93 14,366",
            "768,38 14,359",
            "768,82 14,386",
            "769,26 14,438",
            "769,70 14,359",
            "770,14 14,339",
            "770,58 14,344",
            "771,02 14,487",
            "771,46 14,341",
            "771,90 14,421",
            "772,35 14,395",
            "772,79 14,429",
            "773,23 14,419",
            "773,67 14,311",
            "774,11 14,390",
            "774,55 14,279",
            "774,99 14,403",
            "775,43 14,388",
            "775,87 14,372",
            "776,31 14,350",
            "776,75 14,387",
            "777,20 14,377",
            "777,64 14,426",
            "778,08 14,339",
            "778,52 14,363",
            "778,96 14,457",
            "779,40 14,317",
            "779,84 14,312",
            "780,28 14,397",
            "780,72 14,366",
            "781,16 14,447",
            "781,60 14,270",
            "782,04 14,284",
            "782,48 14,324",
            "782,92 14,357",
            "783,36 14,311",
            "783,80 14,274",
            "784,24 14,319",
            "784,68 14,306",
            "785,12 14,272",
            "785,56 14,203",
            "786,00 14,289",
            "786,44 14,254",
            "786,88 14,308",
            "787,32 14,287",
            "787,76 14,218",
            "788,20 14,275",
            "788,64 14,287",
            "789,08 14,203",
            "789,52 14,319",
            "789,96 14,259",
            "790,40 14,190",
            "790,84 14,195",
            "791,28 14,236",
            "791,72 14,310",
            "792,16 14,257",
            "792,60 14,250",
            "793,04 14,329",
            "793,48 14,225",
            "793,92 14,298",
            "794,36 14,267",
            "794,80 14,106",
            "795,24 14,249",
            "795,68 14,300",
            "796,12 14,204",
            "796,56 14,239",
            "797,00 14,190",
            "797,43 14,291",
            "797,87 14,265",
            "798,31 14,173",
            "798,75 14,217",
            "799,19 14,129",
            "799,63 14,043",
            "800,07 14,188",
            "800,51 14,226",
            "800,95 14,188",
            "801,39 14,153",
            "801,83 14,193",
            "802,27 14,140",
            "802,70 14,158",
            "803,14 14,167",
            "803,58 14,079",
            "804,02 14,177",
            "804,46 14,186",
            "804,90 13,977",
            "805,34 14,147",
            "805,78 13,986",
            "806,22 14,090",
            "806,65 13,970",
            "807,09 14,099",
            "807,53 14,078",
            "807,97 14,047",
            "808,41 14,039",
            "808,85 14,162",
            "809,29 14,072",
            "809,72 14,060",
            "810,16 14,105",
            "810,60 13,969",
            "811,04 14,127",
            "811,48 14,084",
            "811,92 14,034",
            "812,35 13,968",
            "812,79 14,028",
            "813,23 14,127",
            "813,67 13,967",
            "814,11 14,008",
            "814,55 14,084",
            "814,98 14,028",
            "815,42 14,054",
            "815,86 14,002",
            "816,30 13,989",
            "816,74 14,055",
            "817,17 14,044",
            "817,61 14,022",
            "818,05 13,914",
            "818,49 13,932",
            "818,93 13,868",
            "819,36 14,085",
            "819,80 13,870",
            "820,24 13,920",
            "820,68 13,989",
            "821,11 13,905",
            "821,55 13,974",
            "821,99 13,885",
            "822,43 13,830",
            "822,87 13,884",
            "823,30 13,912",
            "823,74 13,890",
            "824,18 13,896",
            "824,62 13,933",
            "825,05 13,869",
            "825,49 13,942",
            "825,93 13,936",
            "826,37 13,819",
            "826,80 13,913",
            "827,24 13,833",
            "827,68 13,871",
            "828,11 13,908",
            "828,55 13,937",
            "828,99 13,813",
            "829,43 13,779",
            "829,86 13,790",
            "830,30 13,786",
            "830,74 13,731",
            "831,17 13,838",
            "831,61 13,800",
            "832,05 13,778",
            "832,49 13,652",
            "832,92 13,895",
            "833,36 13,881",
            "833,80 13,744",
            "834,23 13,690",
            "834,67 13,784",
            "835,11 13,744",
            "835,54 13,817",
            "835,98 13,791",
            "836,42 13,834",
            "836,85 13,767",
            "837,29 13,709",
            "837,73 13,662",
            "838,16 13,815",
            "838,60 13,784",
            "839,04 13,638",
            "839,47 13,778",
            "839,91 13,783",
            "840,35 13,732",
            "840,78 13,681",
            "841,22 13,530",
            "841,66 13,652",
            "842,09 13,598",
            "842,53 13,675",
            "842,96 13,711",
            "843,40 13,650",
            "843,84 13,672",
            "844,27 13,603",
            "844,71 13,561",
            "845,14 13,574",
            "845,58 13,530",
            "846,02 13,713",
            "846,45 13,547",
            "846,89 13,732",
            "847,32 13,620",
            "847,76 13,611",
            "848,20 13,566",
            "848,63 13,584",
            "849,07 13,587",
            "849,50 13,584",
            "849,94 13,539",
            "850,38 13,510",
            "850,81 13,557",
            "851,25 13,533",
            "851,68 13,467",
            "852,12 13,518",
            "852,55 13,586",
            "852,99 13,528",
            "853,43 13,404",
            "853,86 13,454",
            "854,30 13,567",
            "854,73 13,585",
            "855,17 13,484",
            "855,60 13,526",
            "856,04 13,573",
            "856,47 13,502",
            "856,91 13,624",
            "857,34 13,501",
            "857,78 13,551",
            "858,21 13,455",
            "858,65 13,490",
            "859,08 13,482",
            "859,52 13,498",
            "859,96 13,406",
            "860,39 13,419",
            "860,83 13,343",
            "861,26 13,517",
            "861,70 13,501",
            "862,13 13,480",
            "862,57 13,325",
            "863,00 13,441",
            "863,43 13,566",
            "863,87 13,534",
            "864,30 13,603",
            "864,74 13,415",
            "865,17 13,405",
            "865,61 13,385",
            "866,04 13,415",
            "866,48 13,506",
            "866,91 13,451",
            "867,35 13,420",
            "867,78 13,323",
            "868,22 13,417",
            "868,65 13,265",
            "869,09 13,328",
            "869,52 13,341",
            "869,95 13,415",
            "870,39 13,281",
            "870,82 13,155",
            "871,26 13,335",
            "871,69 13,385",
            "872,13 13,372",
            "872,56 13,204",
            "872,99 13,354",
            "873,43 13,413",
            "873,86 13,427",
            "874,30 13,216",
            "874,73 13,300",
            "875,17 13,248",
            "875,60 13,394",
            "876,03 13,355",
            "876,47 13,254",
            "876,90 13,408",
            "877,34 13,229",
            "877,77 13,213",
            "878,20 13,204",
            "878,64 13,051",
            "879,07 13,230",
            "879,50 13,127",
            "879,94 13,273",
            "880,37 13,273",
            "880,81 13,143",
            "881,24 13,307",
            "881,67 13,210",
            "882,11 13,260",
            "882,54 13,299",
            "882,97 13,237",
            "883,41 13,260",
            "883,84 13,293",
            "884,27 13,238",
            "884,71 13,133",
            "885,14 13,241",
            "885,57 13,284",
            "886,01 13,269",
            "886,44 13,093",
            "886,87 13,311",
            "887,31 13,135",
            "887,74 13,176",
            "888,17 13,171",
            "888,61 13,145",
            "889,04 13,153",
            "889,47 13,065",
            "889,91 13,133",
            "890,34 13,072",
            "890,77 13,041",
            "891,20 13,062",
            "891,64 13,157",
            "892,07 13,103",
            "892,50 13,092",
            "892,94 13,163",
            "893,37 13,134",
            "893,80 13,279",
            "894,23 13,225",
            "894,67 13,195",
            "895,10 13,145",
            "895,53 13,116",
            "895,97 13,060",
            "896,40 13,190",
            "896,83 13,063",
            "897,26 13,145",
            "897,70 13,120",
            "898,13 13,006",
            "898,56 13,133",
            "898,99 13,058",
            "899,43 13,102",
            "899,86 13,140",
            "900,29 13,129",
            "900,72 13,068",
            "901,15 13,105",
            "901,59 13,092",
            "902,02 13,029",
            "902,45 13,101",
            "902,88 13,052",
            "903,32 13,133",
            "903,75 13,033",
            "904,18 13,027",
            "904,61 13,253",
            "905,04 13,136",
            "905,48 13,177",
            "905,91 13,143",
            "906,34 13,106",
            "906,77 13,160",
            "907,20 13,033",
            "907,64 13,163",
            "908,07 13,035",
            "908,50 12,850",
            "908,93 12,866",
            "909,36 13,031",
            "909,79 12,868",
            "910,23 13,011",
            "910,66 13,061",
            "911,09 13,009",
            "911,52 13,049",
            "911,95 13,049",
            "912,38 13,090",
            "912,82 13,105",
            "913,25 13,106",
            "913,68 13,117",
            "914,11 13,084",
            "914,54 13,110",
            "914,97 13,250",
            "915,40 13,057",
            "915,83 13,000",
            "916,27 13,014",
            "916,70 13,007",
            "917,13 12,889",
            "917,56 12,979",
            "917,99 13,036",
            "918,42 12,871",
            "918,85 12,956",
            "919,28 13,038",
            "919,71 12,831",
            "920,15 12,969",
            "920,58 12,933",
            "921,01 12,899",
            "921,44 12,870",
            "921,87 13,121",
            "922,30 12,924",
            "922,73 12,929",
            "923,16 12,899",
            "923,59 12,909",
            "924,02 12,946",
            "924,45 12,928",
            "924,88 12,882",
            "925,32 12,967",
            "925,75 12,783",
            "926,18 13,051",
            "926,61 12,876",
            "927,04 13,041",
            "927,47 12,924",
            "927,90 12,996",
            "928,33 12,803",
            "928,76 12,906",
            "929,19 12,927",
            "929,62 12,747",
            "930,05 12,918",
            "930,48 12,841",
            "930,91 12,873",
            "931,34 12,710",
            "931,77 12,754",
            "932,20 12,679",
            "932,63 12,971",
            "933,06 12,780",
            "933,49 12,923",
            "933,92 12,682",
            "934,35 12,758",
            "934,78 12,782",
            "935,21 12,755",
            "935,64 14,204",
            "936,07 12,735",
            "936,50 12,565",
            "936,93 12,905",
            "937,36 12,895",
            "937,79 12,977",
            "938,22 12,805",
            "938,65 12,761",
            "939,08 12,712",
            "939,51 12,700",
            "939,94 12,843",
            "940,37 12,769",
            "940,80 12,886",
            "941,23 12,650",
            "941,66 12,723",
            "942,09 12,657",
            "942,51 12,579",
            "942,94 12,274",
            "943,37 12,472",
            "943,80 12,663",
            "944,23 12,579",
            "944,66 12,640",
            "945,09 12,826",
            "945,52 12,592",
            "945,95 12,751",
            "946,38 12,716",
            "946,81 12,674",
            "947,24 12,573",
            "947,67 12,723",
            "948,09 12,650",
            "948,52 12,675",
            "948,95 12,825",
            "949,38 12,773",
            "949,81 12,771",
            "950,24 12,674",
            "950,67 12,796",
            "951,10 12,586",
            "951,53 12,829",
            "951,95 12,703",
            "952,38 12,663",
            "952,81 12,674",
            "953,24 12,767",
            "953,67 12,832",
            "954,10 12,767",
            "954,53 12,762",
            "954,95 12,837",
            "955,38 12,674",
            "955,81 12,753",
            "956,24 12,897",
            "956,67 12,635",
            "957,10 12,571",
            "957,53 12,614",
            "957,95 12,868",
            "958,38 12,738",
            "958,81 12,599",
            "959,24 12,678",
            "959,67 12,692",
            "960,09 12,379",
            "960,52 12,713",
            "960,95 12,835",
            "961,38 12,686",
            "961,81 12,836",
            "962,24 12,954",
            "962,66 12,780",
            "963,09 12,847",
            "963,52 12,861",
            "963,95 12,715",
            "964,38 12,749",
            "964,80 12,773",
            "965,23 12,796",
            "965,66 12,699",
            "966,09 12,845",
            "966,51 12,698",
            "966,94 12,763",
            "967,37 12,717",
            "967,80 12,848",
            "968,22 12,642",
            "968,65 12,699",
            "969,08 12,626",
            "969,51 12,779",
            "969,93 12,841",
            "970,36 12,923",
            "970,79 12,897",
            "971,22 12,858",
            "971,64 12,898",
            "972,07 12,701",
            "972,50 12,806",
            "972,93 12,923",
            "973,35 12,884",
            "973,78 12,823",
            "974,21 12,808",
            "974,64 12,957",
            "975,06 12,815",
            "975,49 13,017",
            "975,92 12,931",
            "976,34 12,908",
            "976,77 13,033",
            "977,20 12,893",
            "977,62 12,885",
            "978,05 12,806",
            "978,48 12,865",
            "978,91 12,859",
            "979,33 12,896",
            "979,76 12,907",
            "980,19 12,725",
            "980,61 12,878",
            "981,04 12,845",
            "981,47 12,886",
            "981,89 12,766",
            "982,32 13,028",
            "982,75 12,636",
            "983,17 13,029",
            "983,60 12,808",
            "984,03 12,929",
            "984,45 12,912",
            "984,88 12,870",
            "985,30 12,830",
            "985,73 13,007",
            "986,16 12,956",
            "986,58 12,792",
            "987,01 12,980",
            "987,44 12,849",
            "987,86 12,942",
            "988,29 12,924",
            "988,71 12,814",
            "989,14 12,920",
            "989,57 12,941",
            "989,99 12,726",
            "990,42 12,833",
            "990,84 12,832",
            "991,27 12,621",
            "991,70 12,609",
            "992,12 12,840",
            "992,55 12,748",
            "992,97 12,954",
            "993,40 12,983",
            "993,83 12,683",
            "994,25 12,860",
            "994,68 12,884",
            "995,10 12,964",
            "995,53 12,942",
            "995,95 12,892",
            "996,38 12,857",
            "996,81 12,908",
            "997,23 12,918",
            "997,66 12,910",
            "998,08 12,850",
            "998,51 12,950",
            "998,93 12,933",
            "999,36 13,011",
            "999,78 12,848",
            "1000,21 12,930",
            "1000,63 12,821",
            "1001,06 12,740",
            "1001,49 12,745",
            "1001,91 12,839",
            "1002,34 12,944",
            "1002,76 12,833",
            "1003,19 12,937",
            "1003,61 12,783",
            "1004,04 12,621",
            "1004,46 12,780",
            "1004,89 12,682",
            "1005,31 12,819",
            "1005,74 12,852",
            "1006,16 12,973",
            "1006,59 13,011",
            "1007,01 12,961",
            "1007,44 12,938",
            "1007,86 12,939",
            "1008,29 12,900",
            "1008,71 12,875",
            "1009,13 12,732",
            "1009,56 13,160",
            "1009,98 12,924",
            "1010,41 12,852",
            "1010,83 13,092",
            "1011,26 12,962",
            "1011,68 13,116",
            "1012,11 12,854",
            "1012,53 12,978",
            "1012,96 12,898",
            "1013,38 12,680",
            "1013,80 12,887",
            "1014,23 13,047",
            "1014,65 13,040",
            "1015,08 12,891",
            "1015,50 12,888",
            "1015,93 12,827",
            "1016,35 13,001",
            "1016,77 12,892",
            "1017,20 12,846",
            "1017,62 13,013",
            "1018,05 12,864",
            "1018,47 12,943",
            "1018,90 12,889",
            "1019,32 12,915",
            "1019,74 12,826",
            "1020,17 12,951",
            "1020,59 12,878",
            "1021,01 13,090",
            "1021,44 12,825",
            "1021,86 12,953",
            "1022,29 12,854",
            "1022,71 12,926",
            "1023,13 12,833",
            "1023,56 12,713",
            "1023,98 12,772",
            "1024,40 12,959",
            "1024,83 12,869",
            "1025,25 12,976",
            "1025,68 13,079",
            "1026,10 12,819",
            "1026,52 12,839",
            "1026,95 12,706",
            "1027,37 13,084",
            "1027,79 12,879",
            "1028,22 12,620",
            "1028,64 12,918",
            "1029,06 12,888",
            "1029,49 12,777",
            "1029,91 12,823",
            "1030,33 13,005",
            "1030,76 12,745",
            "1031,18 12,981",
            "1031,60 12,705",
            "1032,02 12,952",
            "1032,45 12,576",
            "1032,87 13,025",
            "1033,29 12,816",
            "1033,72 12,638",
            "1034,14 12,764",
            "1034,56 12,816",
            "1034,99 12,865",
            "1035,41 12,977",
            "1035,83 13,073",
            "1036,25 13,248",
            "1036,68 12,962",
            "1037,10 12,797",
            "1037,52 13,076",
            "1037,94 12,845",
            "1038,37 13,118",
            "1038,79 12,806",
            "1039,21 12,955",
            "1039,63 12,885",
            "1040,06 13,184",
            "1040,48 12,819",
            "1040,90 12,917",
            "1041,32 13,086",
            "1041,75 12,738",
            "1042,17 13,136",
            "1042,59 12,789",
            "1043,01 12,908",
            "1043,44 12,507",
            "1043,86 12,846",
            "1044,28 13,046",
            "1044,70 12,927",
            "1045,12 12,878",
            "1045,55 13,030",
            "1045,97 12,808",
            "1046,39 13,222",
            "1046,81 12,680",
            "1047,24 12,972",
            "1047,66 13,410",
            "1048,08 12,864",
            "1048,50 12,800",
            "1048,92 12,956",
            "1049,34 12,620",
            "1049,77 13,005",
            "1050,19 13,023",
            "1050,61 12,867",
            "1051,03 13,141",
            "1051,45 13,468",
            "1051,88 13,103",
            "1052,30 12,547",
            "1052,72 13,253",
            "1053,14 12,228",
            "1053,56 13,259",
            "1053,98 12,257",
            "1054,40 13,057",
            "1054,83 13,538",
            "1055,25 13,111",
            "1055,67 13,207",
            "1056,09 12,838",
            "1056,51 13,275",
            "1056,93 13,312",
            "1057,35 13,705",
            "1057,77 13,335",
            "1058,20 13,246",
            "1058,62 12,632",
            "1059,04 12,693",
            "1059,46 12,895",
            "1059,88 12,636",
            "1060,30 12,494",
            "1060,72 12,898",
            "1061,14 13,020",
            "1061,56 13,258",
            "1061,99 13,111",
            "1062,41 12,491",
            "1062,83 12,734",
            "1063,25 12,656",
            "1063,67 12,714",
            "1064,09 13,506",
            "1064,51 13,028",
            "1064,93 12,846",
            "1065,35 13,085",
            "1065,77 12,407",
            "1066,19 12,916",
            "1066,61 12,894",
            "1067,03 13,597",
            "1067,45 12,812",
            "1067,88 13,370",
            "1068,30 12,689",
            "1068,72 13,038",
            "1069,14 12,763",
            "1069,56 13,369",
            "1069,98 12,239",
            "1070,40 13,149",
            "1070,82 12,578",
            "1071,24 12,876",
            "1071,66 12,751",
            "1072,08 12,811",
            "1072,50 12,797",
            "1072,92 12,632",
            "1073,34 13,331",
            "1073,76 12,420",
            "1074,18 13,121",
            "1074,60 13,114",
            "1075,02 13,049",
            "1075,44 12,507",
            "1075,86 13,310",
            "1076,28 12,696",
            "1076,70 13,348",
            "1077,12 13,266",
            "1077,54 12,763",
            "1077,96 13,227",
            "1078,38 12,974",
            "1078,80 12,708",
            "1079,22 13,114",
            "1079,64 13,234",
            "1080,06 13,072",
            "1080,48 13,058",
            "1080,90 12,366",
            "1081,31 12,801",
            "1081,73 11,750",
            "1082,15 11,910",
            "1082,57 12,570",
            "1082,99 12,839",
            "1083,41 12,759",
            "1083,83 12,907",
            "1084,25 12,975",
            "1084,67 13,401",
            "1085,09 12,148",
            "1085,51 13,757",
            "1085,93 12,564",
            "1086,35 13,404",
            "1086,77 12,455",
            "1087,18 12,523",
            "1087,60 13,949",
            "1088,02 12,915",
            "1088,44 13,062",
            "1088,86 11,595",
            "1089,28 12,589",
            "1089,70 13,617",
            "1090,12 13,631",
            "1090,54 13,189",
            "1090,96 13,427",
            "1091,37 12,592",
            "1091,79 13,202",
            "1092,21 13,405",
            "1092,63 12,460",
            "1093,05 13,702",
            "1093,47 13,943",
            "1093,89 12,182",
            "1094,30 11,091",
            "1094,72 13,064",
            "1095,14 12,162",
            "1095,56 12,342",
            "1095,98 11,617",
            "1096,40 11,363",
            "1096,82 11,805",
            "1097,23 12,033",
            "1097,65 11,113",
            "1098,07 13,340",
            "1098,49 13,141",
            "1098,91 12,553",
            "1099,33 12,190",
            "1099,74 13,655",
            "1100,16 11,938",
            "1100,58 13,418",
            "1101,00 11,465",
            "1101,42 13,671",
            "1101,83 13,290",
            "1102,25 11,839",
            "1102,67 14,033",
            "1103,09 14,124",
            "1103,51 14,062",
            "1103,92 10,477",
            "1104,34 12,363",
            "1104,76 12,988",
            "1105,18 11,891",
            "1105,59 13,045",
            "1106,01 13,317",
            "1106,43 14,686",
            "1106,85 13,127",
            "1107,27 12,885",
            "1107,68 13,445",
            "1108,10 14,153",
            "1108,52 12,536",
            "1108,94 12,716",
            "1109,35 13,299",
            "1109,77 14,850",
            "1110,19 12,104",
            "1110,60 9,628",
            "1111,02 13,851",
            "1111,44 10,238",
            "1111,86 14,290",
            "1112,27 14,689",
            "1112,69 14,396",
            "1113,11 12,138",
            "1113,53 12,092",
            "1113,94 14,188",
            "1114,36 15,963",
            "1114,78 14,021",
            "1115,19 13,036",
            "1115,61 12,451",
            "1116,03 14,401",
            "1116,44 14,403",
            "1116,86 10,772",
            "1117,28 11,079",
            "1117,70 12,492",
            "1118,11 16,304",
            "1118,53 12,916",
            "1118,95 11,932",
            "1119,36 21,875",
            "1119,78 -9,375",
            "1120,20 30,000",
            "1120,61 13,235",
            ">>>>>",
            ">>>>>",
            ">>>>>",
            ">>>>>"];
        this.tbm_4 = [">>>>>End Processed Spectral Data<<<<<"];

        this.line_no = 0;
        this.char_no = 0;
        this.target = target;
        this.txt = "undefined";
        this.total_lines = 0;
        this.DEFAULT_TYPE_SPEED = 25;
        this.DEFAULT_LINE_SPEED = 200;

        this.cleanTarget();
        var that = this;
        this.final_callback = final_cb;

        switch (content_name) {
            case "intro":
                this.typeContentCharByChar(this.intro, 50, 
                    function(){
                        that.stop();
                        if(that.QUICK_VERSION){
                            setTimeout(function(){
                                document.querySelector(".intro_enter_button").click();
                                console.warn("debug mode: clicked on 'explore'");
                            }, 2000);
                        }
                    });
                break;

            case "transition_before_microscope":
                this.typeContentCharByChar(this.tbm_1, that.DEFAULT_TYPE_SPEED,
                    function () {
                        that.typeContentCharByChar(that.tbm_2, that.DEFAULT_TYPE_SPEED,
                            function () {
                                that.typeContentLineByLine(that.tbm_3, 3, 10,
                                    function () {
                                        that.typeContentCharByChar(that.tbm_4, that.DEFAULT_TYPE_SPEED,
                                            function () {
                                                that.stop()
                                            }
                                        )
                                    }
                                )
                            }
                        )
                    }
                );
                break;

            default:
                console.log("i dont have text for type: " + content_name);
        }
    }

    cleanTarget() {
        gsap.timeline().to(".r3f_canvas > div > div", { overflow: "hidden" })
        document.getElementsByClassName(this.target)[0].innerHTML = "";
    }

    typeContentCharByChar(content, speed, callback) {
        if (this.QUICK_VERSION) {
            speed = 1;
        }
        this.txt = content;
        this.char_no = 0;
        this.line_no = 0;
        this.total_lines = this.txt.length;

        function tccbc(tw) {
            if (tw.line_no < tw.total_lines) {
                let line = tw.txt[tw.line_no]
                if (tw.char_no < line.length) {
                    tw.typeIn(line.charAt(tw.char_no));
                    tw.char_no++;
                } else {
                    tw.typeInNewLine();
                    tw.char_no = 0;
                    tw.line_no++;
                }
            } else {
                clearInterval(tw.routine);
                try {
                    callback();
                } catch (error) {
                    console.log("Damn", error);
                }
            }
        }

        var that = this;

        this.routine = setInterval(function () {
            tccbc(that);
        }, speed);
    }

    typeContentLineByLine(content, speed, skipEveryNLines = 0, callback) {
        if (this.QUICK_VERSION) {
            this.total_lines = 10;
        }
        this.txt = content;
        this.line_no = 0;
        this.total_lines = this.txt.length;

        function tclbl(tw) {
            if (tw.line_no < tw.total_lines) {
                tw.typeIn(tw.txt[tw.line_no]);
                tw.typeInNewLine();
                tw.scrollToBottom();
                tw.line_no += 1 + skipEveryNLines;
            } else {
                tw.typeIn(tw.txt[tw.txt.length - 1]);
                tw.typeInNewLine();
                tw.scrollToBottom();
                clearInterval(tw.routine);
                try {
                    callback();
                } catch (error) {
                    console.log("Damn", error);
                }
            }
        }

        var that = this;

        this.routine = setInterval(function () {
            tclbl(that);
        }, speed);
    }

    scrollToBottom() {
        var element = document.getElementsByClassName("transition_before_microscope")[0];
        element.scrollTop = element.scrollHeight;
    }

    typeIn(content) {
        document.getElementsByClassName(this.target)[0].innerHTML += content;
    }

    typeInNewLine() {
        document.getElementsByClassName(this.target)[0].innerHTML += '<br>';
    }

    stop() {
        clearInterval(this.routine);
        this.line_no = this.txt.length
        console.log("stopped");
        gsap.timeline().to(".r3f_canvas > div > div", { overflow: "auto" })

        try {
            if(this.final_callback){
                this.final_callback();
                console.log("ran final callback");
            } 
        } catch (error) {         
            console.log("final callback boomed: ", error);
        }
    }
}

export default MyTypeWriter