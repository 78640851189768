import React, { useRef, useEffect, useState } from 'react'
import { useScroll, Html } from '@react-three/drei'
import { useFrame } from '@react-three/fiber'
import { useGlobalState } from './App';
import ChemicalScroll from './microscopic_view/ChemicalScroll';

export default function ScrollBar(props) {
  const [scroll_pos, setScrollPosition] = useGlobalState('scroll_pos');
  const [scroll_height, setSHeight] = useState(0);
  const [current_page, setCurrentPage] = useGlobalState('current_page');
  const [isAtMicroscope] = useGlobalState('isAtMicroscope');
  const [viewportSize, setViewportSize] = useGlobalState('viewport_size');
  
  const npages = 7;

  useEffect( () => {
    let elem = document.querySelector('.scrollbar_container');
    let rect = elem.getBoundingClientRect();

    //hack to get viewport height and resize
    //let info = [];
    for (var key in rect) {
      if(typeof rect[key] !== 'function') {
        if(key === 'height'){
          setSHeight(rect[key]);
        }
      }
    }
  });

  //TODO replace scroll_height with viewportSize.height
  //console.log("heights", scroll_height, viewportSize?.height);

  let glow_y = -scroll_height + scroll_height * ((current_page-1)/npages);
  let shadow_value = '0px ' + glow_y + 'px 10px rgb(255, 255, 255, 0.9)';
  let pointer_width = isAtMicroscope ? (viewportSize.width- 36) +'px' : '36px';
  let pointer_y = 'calc(' + scroll_pos*100 + '% - 32px)';
  

  return (
      <div className="scrollbar_container">
        <div className="scrollbar"></div>
        <div className='scrollglow' style={{boxShadow: shadow_value}}></div>
      </div>
  );
}
