import React, { useEffect, useRef, useState } from 'react'
import { useGlobalState } from '../App';
import { Html, useScroll } from '@react-three/drei'
import { useFrame } from '@react-three/fiber'
import { EffectComposer, Selection, Select, Noise } from '@react-three/postprocessing'
import { gsap, } from 'gsap';
import MyTypeWriter from '../effects/MyTypeWriter';

export default function Intro(props) {
    const scroll = useScroll();
    const ball = useRef();
    const ANIMATION_DURATION = 2;
    const EASE_FUNCTION = "power4";
    const z_far = -5000;
    
    const [cursor_icon, setCursor] = useGlobalState('cursor_icon');
    const audio_background = useRef();
    const [adjusted_background_audio, adjustedBackAudio] = useState(false);
    
    const [lock_function] = useGlobalState('lock_scroll_function');


    function setScrollLock(lock){
        let lf = lock_function;
        if(lock){
            document.querySelector('.App').addEventListener('wheel', lf, {passive: false});
        } else {
            document.querySelector('.App').removeEventListener('wheel', lf, {passive: false});
        }
        console.log("intro set scroll lock:", lock);
    }

    useEffect(() => {
        if (scroll.offset === 0) {
            setScrollLock(true);

            let a = gsap.timeline({ paused: true })                
                .to(ball.current.position, {
                    duration: 1, ease: EASE_FUNCTION,
                    x: 0, y: 0, z: -0.25
                })
                .to(ball.current, { opacity: 0 }, 2)
                .to('.intro_logo_wrapper', { duration: ANIMATION_DURATION, opacity: 1 }, 1.5)
                .add(function () {

                    var enter_button = document.getElementsByClassName("intro_enter_button");
                    enter_button[0].addEventListener("click", function (event) {
                        try {
                            audio_background.current.play();
                            console.log("background audio is playing");
                            // audio_background.current.pause();
                            // console.log("paused background sound [disable hack here]");
                        } catch (error) {
                            console.log("background audio failed to start");
                            return;
                        }
                        gsap.timeline()
                            .to('.intro_logo_wrapper', { duration: ANIMATION_DURATION, opacity: 0 }, 0)
                            .to(ball.current.position, {
                                duration: ANIMATION_DURATION, ease: EASE_FUNCTION,
                                x: 0, y: 0, z: z_far
                            }, 2)
                            .to('.top_right_loader', { duration: ANIMATION_DURATION, opacity: 1 }, 4)
                            .to('.top_left_logo_blue', { duration: ANIMATION_DURATION, opacity: 1 }, 4)
                            .add(function () {
                                props.meteorite.current.fall();
                                event.target.style.pointerEvents = 'none'; //.intro_logo_wrapper pointer events to none
                                setCursor("url('./res/cursor_circle.svg') 48 48, auto");
                            });
                    }, false);
                })
                .add(function () {
                    new MyTypeWriter("intro_text", "intro", function () {
                        gsap.timeline()
                            .to('.intro_enter_button', { duration: ANIMATION_DURATION, opacity: 1 })
                            .to('.intro_enter_button', { alpha: 0.33, repeatDelay: 0, repeat: 20, duration: 1.25, yoyo: true });
                    });
                }, 1.5);

            a.play();
            console.log("started animation");
        }
    }, []);

    // useEffect(() => {
    //     if (adjusted_background_audio) {
    //         return;
    //     }
    //     try {
    //         audio_background.current.volume = 0.66;
    //         adjustedBackAudio(true);
    //         audio_background.current.pause();
    //         console.log("adjusted background sound");
    //     } catch (error) {
    //         console.log("could not adjust background audio");

    //     }
    // });

    return (
        <>
            <Html>
                <audio ref={audio_background} src="/audio/Ambient_lores.mp3" loop autoPlay></audio>
            </Html>
            <mesh ref={ball} position={[0, 0, z_far]} opacity={1} >
                <sphereBufferGeometry attach="geometry" args={[1.1, 16, 16]} />
                <meshBasicMaterial attach="material" toneMApped={false} color={"#fff"} />
            </mesh>
        </>
    );
}