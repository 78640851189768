import React from 'react';
import { useFrame, useThree } from '@react-three/fiber';
import { useScroll } from '@react-three/drei'

// CHECK: https://greensock.com/forums/topic/25648-change-backgroundcolor-on-scroll/

export default function Background(props) {
    const { gl } = useThree();
    const scroll_data = useScroll();

    const start_color = 0;
    const end_color = 19;

    useFrame(() => {
        try {
            const current_color = Math.floor(start_color + scroll_data.offset * end_color);
            let c = (current_color).toString(16).padStart(2, '0');
            let color_code = '#' + c + c + c;
            gl.setClearColor(color_code);            
        } catch (error) {
            console.warn("BOOM", error);
        }
    });

    return (<mesh />);
}