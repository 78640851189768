import './style/App.css';
import './style/Loading.css'
import './style/TopRightOverlay.css';
import './style/AudioOverlay.css';
import './style/ScrollableContent.css';
import './style/ScrollToContinue.css';
import './style/TopLeftLogo.css';
import './style/CreditsOverlay.css';
import './style/ChemicalScroll.css';
import './style/Sponsors.css';
import './style/Mobile.css';
import React, { Suspense, useEffect, useRef } from 'react';
import { useCookies } from 'react-cookie';
import { Canvas } from '@react-three/fiber';
import { CameraShake, useProgress } from '@react-three/drei';
import { createGlobalState } from 'react-hooks-global-state';

import ScrollableContent from './ScrollableContent';
import ScrollBar from './ScrollBar';
import IntroOverlay from './overlays/IntroOverlay';
import TopLeftLogo from './overlays/TopLeftLogo';
import TopRightOverlay from './overlays/TopRightOverlay';
import TopRightLoader from './overlays/TopRightLoader';
import ScrollToContinue from './overlays/ScrollToContinue';
import LoadingScreen from './transitions/LoadingScreen';
import TransitionBeforeMicroscope from "./transitions/TransitionBeforeMicroscope";
import ChemicalScroll from "./microscopic_view/ChemicalScroll";
import { BrowserView, MobileView } from 'react-device-detect';
import AudioOverlay from './overlays/AudioOverlay';
import Mobile from './Mobile';
import ScrollDownArrow from './overlays/ScrollDownArrow';

//scroll state
const initialState = {
  DEBUG_MODE: true,
  TOTAL_SECTIONS: 7,
  INTRO_SECTION: 1,
  LYRICS_SECTION: 2,
  MICROSCOPE_TRANSITION: 3,
  MICROSCOPE_SECTION: 4,
  MICROSCOPE_EXT_SECTION: 5,
  CREDITS_SECTION: 6,
  SPONSORS_SECTION: 7,
  TOTAL_CHEMICALS: 6,
  current_page: 0,
  scroll_pos: 0,
  scroll_y: 0,
  scroll_inside_microscope: [3 / 7, 4 / 7],
  isLoading: true,
  cursor_icon: 'default',
  isAtMicroscope: false,
  viewport_size: null,
  debug_value: 0,
  chemical_group: null,
  chemical_index: 0,
  finished_intro: false,
  audio_overlay: undefined,
  scroll_descent: false,
  ongoing_descent: false,
  descent_progress: 0,
  lock_scroll_function: function preventScroll(e) {
    e.preventDefault();
    e.stopPropagation();
    return false;
  }
}

export const { useGlobalState } = createGlobalState(initialState);

function App() {
  const { active } = useProgress()
  const [cursor_icon, setCursor] = useGlobalState('cursor_icon');
  const [isLoading, setLoading] = useGlobalState('isLoading');
  const [gao, setAudioOverlay] = useGlobalState('audio_overlay');
  const [cookies, setCookie] = useCookies(['butterfly']);
  const audio_overlay = useRef();
  const scrollable_content = useRef();

  useEffect(() => {
    window.registerButterfly = registerButterfly;
    if (active) {
      //console.log("is loading=", isLoading);
    } else {
      setLoading(false);
      if (gao === undefined) {
        setAudioOverlay(audio_overlay);
      }
    }
  });

  function registerButterfly(newName) {
    var expiry_date = new Date();
    expiry_date.setFullYear(expiry_date.getFullYear() + 1);
    setCookie('butterfly', newName, { path: '/', expires: expiry_date });
    console.log("Merci beaucoup! You can now refresh the page.");
  }

  return (
    <>
      <BrowserView className="App">
        {
        // cookies.butterfly && (
        //   cookies.butterfly === 'daniel' ||
        //   cookies.butterfly === 'rita' ||
        //   cookies.butterfly === 'mathilde' ||
        //   cookies.butterfly === 'paula' ||
        //   cookies.butterfly === 'tatiana')
          true ?
          <div className="App" style={{ cursor: cursor_icon }} >
            <Canvas
              className="r3f_canvas"
              resize={{ scroll: false }}
              onCreated={({ gl }) => { gl.setClearColor('#000000') }}
              camera={{ position: [0, 0, 1], fov: 55 }}
            >
              <Suspense fallback={<LoadingScreen />} r3f>
                <ambientLight intensity={0.6} castShadow />
                <ScrollableContent ref={scrollable_content} />
              </Suspense>
            </Canvas>
            <IntroOverlay />
            {active ? null :
              <>
                <TransitionBeforeMicroscope />
                <TopLeftLogo />
                <TopRightLoader />
                <TopRightOverlay />
                <ChemicalScroll />
                <ScrollDownArrow />
                <ScrollToContinue />
                <ScrollBar />
                <AudioOverlay ref={audio_overlay} scrollable_content={scrollable_content} />
              </>
            }
          </div>
          :
          <div className="landing_page">
            <img src="./res/landing_page_background.svg" />
            <div>Dear stranger,<br />soon we will meet</div>
            <p>Official opening 9th of November</p>
          </div>
        }
        <div></div>
      </BrowserView>
      <MobileView>
        <Mobile />        
      </MobileView>

    </>
  );
}

export default App;
