/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import { useGLTF } from '@react-three/drei'
import React, { forwardRef } from 'react'

const MeteoriteReal = forwardRef(function (props, ref) {
  const { nodes, materials } = useGLTF('./res/meteorites/earthy_surface.gltf');
  const scale = 43;

  return (
    <group dispose={null}>
      <mesh ref={ref}
        receiveShadow
        castShadow
        material-transparent 
        material-opacity={props.opacity}
        position={props.position}

        geometry={nodes.Cube004.geometry}
        material={materials['Material.002']}
        rotation={[-Math.PI / 2, 0, 0]}

        scale={[scale, 1.2*scale, 1.6*scale]}
      />
    </group>
  )
});

export default MeteoriteReal;

useGLTF.preload('./res/meteorites/earthy_surface.gltf')
