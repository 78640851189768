import React from 'react';
import "../style/TransitionBeforeMicroscope.css"

function TransitionBeforeMicroscope(props) {
  return (
    <>
      <div className="transition_before_microscope">
        <span className="tbm_text">
        </span>
        <span  className="tbm_cursor">|</span>
      </div>
    </>
  )
}

export default TransitionBeforeMicroscope