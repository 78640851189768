import { Html, useProgress } from '@react-three/drei'
import { useFrame } from '@react-three/fiber';

import React, { useCallback, useEffect, useState } from 'react';
import { useGlobalState } from '../App';

export default function LoadingScreen() {
  const { active, progress, errors, item, loaded, total } = useProgress()
  const [count, setCount] = useState(0);
  const [perc, setPerc] = useState(0);

  useFrame((state, delta) => {
    let elapsed = state.clock.elapsedTime;
    setCount(count + 1);

    //awful stuff -D

    let new_perc = perc;

    if (perc < 50) {
      new_perc += delta * 10;
    } else if (perc < 75) {
      new_perc += 0.3 / elapsed;
      // console.log("mode 2")
    } else {
      new_perc += 0.15 / elapsed;
      // console.log("mode 3")
    }

    if (new_perc > 99) {
      new_perc = 99;
    } else {
      setPerc(new_perc);
    }
  });

  return (
    // <Html wrapperClass='loading_wrapper'>
    //   <div className='loading_screen'>
    //     {perc.toFixed(0)}% {errors}
    //   </div>
    // </Html>
    <Html wrapperClass='loading_wrapper'>
      <div className='loading_screen'>
        {perc.toFixed(0)}% {errors}
      </div>
    </Html>
  )
}