import React, { useState, useRef } from 'react';
import { gsap } from 'gsap';

function TopLeftLogo(props) {
  const [show_overlay, setVisible] = useState(false);
  const overlay = useRef();

  function openLogoOverlay() {

    gsap.timeline()
      .call(setVisible, [true])
      .to('.top_left_logo_blue', { duration: 1, opacity: 0 }, 0)
      .to('.top_left_logo_black', { duration: 1, opacity: 1 }, 0)
      .to('.top_left_logo_container', { duration: 1, opacity: 1 }, 0)
  }

  function closeLogoOverlay() {
    gsap.timeline()
      .to('.top_left_logo_blue', { duration: 1, opacity: 1 }, 0)
      .to('.top_left_logo_black', { duration: 1, opacity: 0 }, 0)
      .to('.top_left_logo_container', { duration: 1, opacity: 0 }, 0)
      .call(setVisible, [false]);
  }

  return (
    <>
      <div className="top_left_logo_blue" onMouseOver={() => openLogoOverlay()}>
        <img src={'./res/top_left_logo_blue.png'} />
      </div>

      <div className="top_left_logo_black">
        <img src={'./res/top_left_logo_black.png'} />
      </div>

      <div className={`top_left_logo_container ${show_overlay ? "show_top_left_text" : ""}`}
        ref={overlay}>

        {/* <div className="top_left_close_button" onClick={(e) => closeLogoOverlay()}>
          <img src={'./res/top_left_logo_close_button.svg'} />
        </div> */}

        <img src={'./res/top_left_logo_text.svg'} />

        <div className="top_left_logo_text">
          <h1>The Butterfly Effect</h1>
          <p>
            The butterfly Effect The Butterfly Effect navigates within the new geopolitical realities we live in and speculates upon a sensory experience of a territory only known through digital imageries. It suggests a future in which Humanity will be able to experience any kind of territory regardless of the spectrum of perception, time and geography, by enabling bystanders to physically engage with remote colonies.
          </p><p>
            The work investigates an immersive form of design that can emulate interplanetary narratives, crossing over technology as archeology of our time, and what constitutes the DNA of any planet: geology.
          </p>
        </div>
        
          <div className="top_left_close_button"  onClick={(e) => closeLogoOverlay()}>
            CLOSE
          </div>
      </div>
    </>
  )
}

export default TopLeftLogo