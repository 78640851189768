import React, { useEffect, useState, forwardRef } from 'react'
import { useGlobalState } from '../App';
import { useFrame } from '@react-three/fiber';
import { useScroll } from '@react-three/drei'

import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

const MicroscopeController = forwardRef( function(props, ref) {
  const [TOTAL_CHEMICALS] = useGlobalState('TOTAL_CHEMICALS');
  const [TOTAL_SECTIONS] = useGlobalState('TOTAL_SECTIONS');
  const [MICROSCOPE_SECTION] = useGlobalState('MICROSCOPE_SECTION');
  const [isAtMicroscope, setAtMicroscope] = useGlobalState('isAtMicroscope');
  const [, setChemicalIndex] = useGlobalState('chemical_index');

  const micro_scroll_min = (1 / TOTAL_SECTIONS) * (MICROSCOPE_SECTION - 1);
  const micro_scroll_max = (1 / TOTAL_SECTIONS) * (MICROSCOPE_SECTION + 0.66);
  const micro_scroll_step = (micro_scroll_max - micro_scroll_min)/ TOTAL_CHEMICALS;

  const meteorite = props.meteorite.current;

  const scroll_data = useScroll();
  const [scroll_Y, setOffset] = useState(0);

  const [previous_index, setPreviousIndex] = useState(0);

  const positions = [
    [0.1, Math.PI / 6],
    [0.3, Math.PI / 4],
    [0.5, Math.PI / 2],
    [0.7, Math.PI / 4],
    [0.9, Math.PI / 6],
    [1.1, Math.PI / 8]
  ];

  useEffect(() => {
    if (isAtMicroscope) {

      let current_index = calculateCurrentindex();
      setChemicalIndex(current_index);

      if (previous_index !== current_index) {
        if (current_index > previous_index) {
          //console.log(`chemindex: ${current_index}  ${previous_index} play`);

          let p = positions[previous_index];
          meteorite.rotate(p[0], p[1]);

        } else {
          //console.log(`chemindex: ${current_index}  ${current_index} reverse`);
          
          let p = positions[current_index];
          meteorite.rotate(p[0], p[1]);
        }
      }
      setPreviousIndex(current_index);
    }
  }, [isAtMicroscope, calculateCurrentindex, previous_index]);

  function calculateCurrentindex() {
    let pim = scroll_Y - micro_scroll_min; //position inside microscope

    let current_index = Math.floor( pim / micro_scroll_step );
    current_index = Math.max(0, current_index);
    current_index = Math.min(current_index, TOTAL_CHEMICALS - 1);

    //console.log(micro_scroll_min.toFixed(2), scroll_Y.toFixed(2), micro_scroll_max.toFixed(2), current_index);

    return current_index;
  }

  useFrame(() => {
    if (Math.abs(scroll_data.offset - scroll_Y) > 0.01) {
      setOffset(scroll_data.offset);
    }
  });

  return (
    <></>
  )
});
export default MicroscopeController