import React, { useState } from 'react'
import { useScroll } from '@react-three/drei'
import { useFrame, useThree } from '@react-three/fiber'
import { useGlobalState } from './App';
import { gsap } from 'gsap';
import MyTypeWriter from './effects/MyTypeWriter';
import GSAPBackdropFilterBlur from './effects/GSAPBackdropFilterBlur';
import { useEffect } from 'react';

export default function ScrollObserver(props) {
  GSAPBackdropFilterBlur.doMagic();

  const section_names = [
    "0",
    "INTRO_SECTION",
    "LYRICS_SECTION",
    "MICROSCOPE_TRANSITION",
    "MICROSCOPE_SECTION",
    "MICROSCOPE_EXT_SECTION",
    "CREDITS_SECTION",
    "SPONSORS_SECTION"
  ];

  const [TOTAL_SECTIONS] = useGlobalState('TOTAL_SECTIONS');

  const [INTRO_SECTION] = useGlobalState('INTRO_SECTION');
  const [LYRICS_SECTION] = useGlobalState('LYRICS_SECTION');
  const [MICROSCOPE_TRANSITION] = useGlobalState('MICROSCOPE_TRANSITION');
  const [MICROSCOPE_SECTION] = useGlobalState('MICROSCOPE_SECTION');
  const [MICROSCOPE_EXT_SECTION] = useGlobalState('MICROSCOPE_EXT_SECTION');
  const [CREDITS_SECTION] = useGlobalState('CREDITS_SECTION');
  const [SPONSORS_SECTION] = useGlobalState('SPONSORS_SECTION');

  const [current_page, setCurrentPage] = useGlobalState('current_page');
  const [isAtMicroscope, setAtMicroscope] = useGlobalState('isAtMicroscope');
  const [, setViewportSize] = useGlobalState('viewport_size');
  const [cursor_icon, setCursor] = useGlobalState('cursor_icon');

  const meteorite = props.meteorite;
  const wall = props.wall;

  const [meteo_zoom_out, setZoomOut] = useState(false);
  const [continue_animation, setContinueAnimation] = useState(undefined);

  //TODO replace scrollpos with data.offset if it's being updated
  const [scroll_pos, setScrollPosition] = useGlobalState('scroll_pos');
  const data = useScroll()

  const [descent, setDescentFlag] = useGlobalState('scroll_descent');
  const DESCENT_SCROLL_THRESHOLD = 1 / TOTAL_SECTIONS - (1 / TOTAL_SECTIONS * 0.2);
  const STAR_CURSOR_THRESHOLD_TOP = 0.15;

  const { size } = useThree();

  const [isAtMicroTransition, setAtMicroTransition] = useState(false);
  const [typewriter, setTypeWriter] = useState(undefined);

  const [scroll_bar_visible, isScrollBarVisible] = useState(false);
  const SCROLLBAR_THRESHOLD = 1 / TOTAL_SECTIONS / 3;

  const ANIMATION_DURATION = 1;

  const [credits_trigger, setCreditsTrigger] = useState(false);

  function calculatePageNumber() {
    let page = Math.min(Math.ceil(scroll_pos * TOTAL_SECTIONS), TOTAL_SECTIONS);
    page = Math.max(page, 1);
    return page;
  }

  function stopTypeWriter() {
    try {
      typewriter.stop();
      setTypeWriter(undefined);
      console.log("stopped typewriter");
    } catch (error) {
      //console.warn("could not stop typewriter");
    }
  }

  function enterDescentSection(from) {
    if (from === 'top') {
      console.log("descent entered from: ", from)
      setDescentFlag(true);

    } else {
      console.log("descent entered from:", from)
      setDescentFlag(false);
    }
  }

  function leaveDescentSection(from) {
    if (from === 'top') {
      console.log("descent exit from: ", from)
      setDescentFlag(true);

    } else {
      console.log("descent exit from:", from)
      setDescentFlag(false);
    }
  }


  function enterLyricsSection() {
    props.audio_controller.current.toggleAudio(true);
  }

  function leaveLyricsSection() {
    props.audio_controller.current.toggleAudio(false);
  }

  function enterMicroscopeTransition(from_section) {
    if (isAtMicroTransition) {
      return;
    }
    setAtMicroTransition(true);
    console.log("entered micro transition");

    document.getElementsByClassName("tbm_text")[0].innerHTML = "";

    if (from_section !== MICROSCOPE_SECTION) {
      gsap.timeline()
        .to(".transition_before_microscope", { duration: 0.25, opacity: 1 }, 0)
        .to(".r3f_canvas", { duration: 1, blur: 8, ease: "none", }, 0)
        .add(function () {
          new MyTypeWriter("tbm_text", "transition_before_microscope", function () {
            console.log("show continue");
            let anim = gsap.timeline({ paused: true })
              .to('.scroll_to_continue', {
                alpha: 0.5, repeatDelay: 0, repeat: -1,
                duration: 1.25, yoyo: true
              },
                0);
            setContinueAnimation(anim);
            anim.play();
          });
        }, 1);
    }
  }

  function leaveMicroscopeTransition() {
    setAtMicroTransition(false);
    console.log("left micro transition");

    gsap.timeline()
      .to(".transition_before_microscope", { duration: 0.25, opacity: 0 })
      .to(".r3f_canvas", { duration: 1, blur: 0.01, ease: "none" })
      .add(function () {
        try {
          continue_animation.pause();
          console.log("stopped 'scroll to continue'");
        } catch (error) {
          console.log("could not stop 'scroll to continue'");
        }
      })
      .to('.scroll_to_continue', { duration: 1, opacity: 0 })
  }

  function enterMicroscope() {
    setAtMicroscope(true);
    console.log("entered microscope");
    meteorite.current.toggleMicroscope(true);

    gsap.timeline()
      .to('.top_right_overlay', { duration: ANIMATION_DURATION, color: "#000000" }, 0)
      .to('.audio_player', { duration: ANIMATION_DURATION, opacity: 0 }, 1)
      .to('.scrollbar_container', { duration: ANIMATION_DURATION, opacity: 1 }, 1)
      .to('.scroll_horizontal_line', { duration: ANIMATION_DURATION, background: "#000000", opacity: 1 }, 1)
      .to(wall.current.material, { duration: ANIMATION_DURATION, opacity: 1 }, 0)
      .to('.scroll_to_continue', { duration: 1, opacity: 0 })
  }

  function leaveMicroscope(next_section) {
    setAtMicroscope(false);
    meteorite.current.toggleMicroscope(false);

    if (next_section === CREDITS_SECTION) {

      gsap.timeline()
        .to('.top_right_overlay', { duration: ANIMATION_DURATION, opacity: 0 }, 0)
        .to('.top_left_logo', { duration: ANIMATION_DURATION, opacity: 0 }, 0)
        .to('.audio_player', { duration: ANIMATION_DURATION, opacity: 0 }, 0)
        .to('.scrollbar_container', { duration: ANIMATION_DURATION, opacity: 0 }, 0)
        .to('.scroll_horizontal_line', { duration: ANIMATION_DURATION, background: "#FFFFFF", opacity: 0 }, 0)
        .to(wall.current.material, { duration: ANIMATION_DURATION, opacity: 0 }, 0)

    } else {
      gsap.timeline()
        .to('.top_right_overlay', { duration: ANIMATION_DURATION, color: "#FFFFFF" }, 0)
        .to('.audio_player', { duration: ANIMATION_DURATION, opacity: 1 }, 0)
        .to('.scrollbar_container', { duration: ANIMATION_DURATION, opacity: 1 }, 0)
        .to('.scroll_horizontal_line', { duration: ANIMATION_DURATION, background: "#FFFFFF", opacity: 1 }, 0)
        .to(".r3f_canvas", { duration: 0.5, blur: 0.001, ease: "none" })
        .to(wall.current.material, { duration: ANIMATION_DURATION, opacity: 0 }, 0)
    }
    console.log("left microscope");
  }

  function enterCredits() {
    if (credits_trigger) {
      console.log("credits already triggered");
      return;
    }
    setCreditsTrigger(true);
    console.log("entered credits");
    gsap.timeline()
      .to('.top_left_logo_container', { duration: ANIMATION_DURATION, opacity: 0 }, 2)
    let doms = document.getElementsByClassName("credits_person");

    let people = [];
    for (let i = 0; i < doms.length; i++) {
      people.push(doms[i]);
    }

    function shuffle(array) {
      let currentIndex = array.length, randomIndex;

      // While there remain elements to shuffle.
      while (currentIndex !== 0) {

        // Pick a remaining element.
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;

        // And swap it with the current element.
        [array[currentIndex], array[randomIndex]] = [
          array[randomIndex], array[currentIndex]];
      }

      return array;
    }

    people = shuffle(people);


    let timeline = gsap.timeline({ paused: true, delay: 2 });

    for (let i = 0; i < people.length; i++) {
      timeline.to(people[i], { duration: 1.5, opacity: 1 }, i * 2);
    };

    timeline.play();
  }

  function leaveCredits() {
    //gsap.to('.top_left_logo_container', { duration: ANIMATION_DURATION, opacity: 1 })
  }

  function enterSponsors() {
    meteorite.current.toggleVisibility(false);
    gsap.timeline()
      .to('.top_left_logo_blue', { duration: 1, opacity: 0 }, 0)
      .to('.top_left_logo_black', { duration: 1, opacity: 0 }, 0)
      .to('.top_left_logo_container', { duration: 1, opacity: 0 }, 0);
  }

  function leaveSponsors() {
    meteorite.current.toggleVisibility(true);
    gsap.timeline()
      .to('.top_left_logo_blue', { duration: 1, opacity: 1 }, 0)
      .to('.top_left_logo_black', { duration: 1, opacity: 0 }, 0)
      .to('.top_left_logo_container', { duration: 1, opacity: 0 }, 0);
  }

  function showScrollBar() {
    gsap.timeline()
      .to('.scrollbar_container', { duration: ANIMATION_DURATION, opacity: 1 })
      .to('.scroll_horizontal_line', { duration: ANIMATION_DURATION, opacity: 1 })
  }

  useEffect(() => {
    const next_page = calculatePageNumber();
    const scroll_position = data.offset;

    if (next_page !== current_page) {
      console.log(section_names[current_page] + " => " + section_names[next_page]);
      switch (next_page) {
        case INTRO_SECTION:
          if (current_page === LYRICS_SECTION) {
            leaveLyricsSection();
          }
          break;

        case LYRICS_SECTION:
          enterLyricsSection();
          break;

        case MICROSCOPE_TRANSITION:
          if (current_page === LYRICS_SECTION) {
            leaveLyricsSection();
          }
          if (isAtMicroscope) {
            leaveMicroscope();
            enterMicroscopeTransition(MICROSCOPE_SECTION);
          } else {
            enterMicroscopeTransition();
          }
          break;

        case MICROSCOPE_SECTION:
          if (isAtMicroTransition) {
            leaveMicroscopeTransition();
            stopTypeWriter();
          }
          if (!isAtMicroscope) {
            enterMicroscope();
          }
          break;

        case MICROSCOPE_EXT_SECTION:
          if (current_page === MICROSCOPE_SECTION) {
            //do nothing
          }
          if (current_page === CREDITS_SECTION) {
            leaveCredits();
          }
          if (!isAtMicroscope) {
            enterMicroscope();
          }
          break;

        case CREDITS_SECTION:
          if (current_page === MICROSCOPE_EXT_SECTION) {
            leaveMicroscope(CREDITS_SECTION);
          }
          if (current_page === SPONSORS_SECTION) {
            leaveSponsors();
          }
          enterCredits();
          break;

        case SPONSORS_SECTION:
          leaveCredits();
          enterSponsors();
          break;

        default:
          console.log("no behaviour for section ", next_page);
      }
    }

    //check absolute scroll positions here
    if (scroll_position > SCROLLBAR_THRESHOLD && !scroll_bar_visible) {
      isScrollBarVisible(true);
      showScrollBar();
    }

    if (!meteo_zoom_out && scroll_pos > 1 / TOTAL_SECTIONS / 2) {
      setZoomOut(true);
      meteorite.current.zoomOut(true);
    }

    if (!descent && scroll_position > DESCENT_SCROLL_THRESHOLD) {
      setDescentFlag(true);
      console.log("descent triggered from above");
    }

    if (descent && scroll_position < DESCENT_SCROLL_THRESHOLD) {
      setDescentFlag(false);
      console.log("descent triggered from below");
    }

    if(current_page > INTRO_SECTION){
      if(scroll_position > STAR_CURSOR_THRESHOLD_TOP && 
        current_page < MICROSCOPE_SECTION){
        setCursor("url('./res/cursor_star.svg') 48 48, auto");
      } else {
        setCursor("default");
      }
    }

    setCurrentPage(next_page);
  });

  useFrame(() => {
    const scroll_position = data.offset;
    setViewportSize(size);
    setScrollPosition(scroll_position);
  });

  return (<mesh />);
}
